export var AttackDieType;
(function (AttackDieType) {
    AttackDieType[AttackDieType["Yellow"] = 1] = "Yellow";
    AttackDieType[AttackDieType["Blue"] = 2] = "Blue";
    AttackDieType[AttackDieType["Red"] = 3] = "Red";
})(AttackDieType || (AttackDieType = {}));
export var DefenseDieType;
(function (DefenseDieType) {
    DefenseDieType[DefenseDieType["Brown"] = 101] = "Brown";
    DefenseDieType[DefenseDieType["Grey"] = 102] = "Grey";
    DefenseDieType[DefenseDieType["Black"] = 103] = "Black";
})(DefenseDieType || (DefenseDieType = {}));
export var CombatEffectSymbol;
(function (CombatEffectSymbol) {
    CombatEffectSymbol[CombatEffectSymbol["Damage"] = 1] = "Damage";
    CombatEffectSymbol[CombatEffectSymbol["Bonus"] = 2] = "Bonus";
    CombatEffectSymbol[CombatEffectSymbol["Shield"] = 3] = "Shield";
})(CombatEffectSymbol || (CombatEffectSymbol = {}));
export const getAllSymbols = () => [
    CombatEffectSymbol.Damage,
    CombatEffectSymbol.Bonus,
    CombatEffectSymbol.Shield,
];
const symbolsOnDice = {
    [AttackDieType.Yellow]: [
        [CombatEffectSymbol.Bonus],
        [CombatEffectSymbol.Damage],
        [CombatEffectSymbol.Damage],
        [CombatEffectSymbol.Damage],
        [CombatEffectSymbol.Damage, CombatEffectSymbol.Damage],
        [CombatEffectSymbol.Damage, CombatEffectSymbol.Damage, CombatEffectSymbol.Bonus],
    ],
    [AttackDieType.Blue]: [
        [CombatEffectSymbol.Bonus],
        [CombatEffectSymbol.Damage],
        [CombatEffectSymbol.Damage],
        [CombatEffectSymbol.Damage, CombatEffectSymbol.Damage],
        [CombatEffectSymbol.Damage, CombatEffectSymbol.Damage, CombatEffectSymbol.Bonus],
        [CombatEffectSymbol.Damage, CombatEffectSymbol.Damage, CombatEffectSymbol.Damage],
    ],
    [AttackDieType.Red]: [
        [CombatEffectSymbol.Bonus],
        [CombatEffectSymbol.Damage],
        [CombatEffectSymbol.Damage, CombatEffectSymbol.Damage],
        [CombatEffectSymbol.Damage, CombatEffectSymbol.Damage, CombatEffectSymbol.Bonus],
        [CombatEffectSymbol.Damage, CombatEffectSymbol.Damage, CombatEffectSymbol.Damage],
        [
            CombatEffectSymbol.Damage,
            CombatEffectSymbol.Damage,
            CombatEffectSymbol.Damage,
            CombatEffectSymbol.Damage,
        ],
    ],
    [DefenseDieType.Brown]: [
        [],
        [],
        [],
        [CombatEffectSymbol.Shield],
        [CombatEffectSymbol.Shield],
        [CombatEffectSymbol.Shield, CombatEffectSymbol.Shield],
    ],
    [DefenseDieType.Grey]: [
        [],
        [],
        [CombatEffectSymbol.Shield],
        [CombatEffectSymbol.Shield],
        [CombatEffectSymbol.Shield, CombatEffectSymbol.Shield],
        [CombatEffectSymbol.Shield, CombatEffectSymbol.Shield],
    ],
    [DefenseDieType.Black]: [
        [],
        [CombatEffectSymbol.Shield],
        [CombatEffectSymbol.Shield],
        [CombatEffectSymbol.Shield, CombatEffectSymbol.Shield],
        [CombatEffectSymbol.Shield, CombatEffectSymbol.Shield],
        [CombatEffectSymbol.Shield, CombatEffectSymbol.Shield, CombatEffectSymbol.Shield],
    ],
};
export const getSymbolsOnBattleDie = (dieType) => {
    return symbolsOnDice[dieType];
};
