import React from 'react';
export default function UnitIconCrossbow() {
    return (React.createElement("g", null,
        React.createElement("g", { strokeDasharray: "none", strokeMiterlimit: "4", strokeOpacity: "1", transform: "matrix(3.71029 0 0 3.71029 .523 .423)" },
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "0.265", d: "M7.949 8.376l.232 3.3h1.213" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "0.265", d: "M6.682 8.376l-.25 3.264h-.945" }),
            React.createElement("rect", { width: "2.474", height: "3.864", x: "6.06", y: "4.567", fill: "#2c89a0", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "0.265", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", rx: "0.057", ry: "0.057", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "0.265", d: "M8.537 4.63c.617.267 1.843 1.517 1.123 2.73" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "0.265", d: "M9.885 7.657c.304-1.038-.804-.663-.804-.663" }),
            React.createElement("rect", { width: "2.497", height: "1.015", x: "6.048", y: "7.427", fill: "#806600", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "0.265", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", rx: "0.057", ry: "0.057", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "#520", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeWidth: "0.265", d: "M6.698 10.715l-.505-.053-.112.73c-.501-.019-.948-.134-1.006.49l1.681-.014z", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { d: "M7.844 10.715l.505-.053.111.73c.502-.019.949-.134 1.006.49l-1.68-.014z", fill: "#520", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeWidth: "0.265", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "0.265", d: "M6.043 4.63c-.983.405-2.726 2.627.997 1.797" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "0.265", d: "M7.166 6.96c-1.012-.382-.045-1.041-.045-1.041" }),
            React.createElement("circle", { cx: "7.297", cy: "3.446", r: "1.669", fill: "#e9c6af", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "0.265", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("ellipse", { cx: "7.643", cy: "3.103", fill: "#000", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "0.225", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", rx: "0.118", ry: "0.191", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("ellipse", { cx: "6.961", cy: "3.104", fill: "#000", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "0.225", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", rx: "0.118", ry: "0.191", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "#803300", fillOpacity: "1", fillRule: "nonzero", stroke: "#520", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "0.265", d: "M7.599 8.963c.844-.345 1.435-1.061 1.76-1.713.353-.755.19-1.496.12-2.277.363.582.532 1.547.13 2.407-.453.967-1.276 1.615-2.01 1.583z", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("rect", { width: "3.778", height: "0.536", x: "8.766", y: "2.427", fill: "#803300", fillOpacity: "1", fillRule: "nonzero", stroke: "#520", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeWidth: "0.265", clipRule: "nonzero", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", imageRendering: "auto", overflow: "visible", rx: "0.057", ry: "0.057", color: "#000", display: "inline", enableBackground: "accumulate", shapeRendering: "auto", textRendering: "auto", transform: "rotate(25.064)", visibility: "visible" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "0.265", d: "M7.68 8.912l-.46-2.594" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "0.265", d: "M9.495 5.031L7.209 6.34" }),
            React.createElement("rect", { width: "1.539", height: "0.139", x: "9.22", y: "2.607", fill: "#803300", fillOpacity: "1", fillRule: "nonzero", stroke: "#2b1100", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "0.265", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", rx: "0", ry: "0", shapeRendering: "auto", textRendering: "auto", transform: "rotate(25.064)", visibility: "visible" }),
            React.createElement("path", { fill: "#b3b3b3", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "0.265", d: "M8.487 7.205l.235-.426.53.534z", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "#f9f9f9", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "0.312", d: "M7.313 5.99l.535.25-.032.298-.519-.243z", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { d: "M7.037 6.602l.532.249.206-.214-.514-.24z", fill: "#f9f9f9", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "0.309", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "#784421", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "0.265", d: "M7.294 2.215c.151.267-.254.324-.562.29-.246.039-.374.24-.474.447-.178.185-.092.445-.077.671.1.154.038.336.055.5-.13.472-1.127-.09-.679-.96.068-.158.014-.598.198-.745.392-.34.164-.54.811-.602.21.01.334-.214.534-.2.437.124.259.419.194.6z" }),
            React.createElement("path", { fill: "#784421", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "0.265", d: "M7.446 1.604c-.097-.014-.219.078-.128.17.017.124-.08.243-.026.366.01.142-.02.314.193.336.501.08.708.218.867.547.095.075.046.208.085.31-.038.231.014.437.087.642a.36.36 0 00.184.282c.12.042.214-.084.3-.146.116-.498.186-.74-.042-1.168-.184-.192-.156-.638-.26-.702-.25-.285-.391-.29-.693-.499-.091-.024-.482-.073-.567-.138z" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "0.265", d: "M7.582 1.477s-.143-.053-.294.215c-.134-.304-.366-.25-.366-.25" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "0.265", d: "M7.27 1.674c-.036-.277.044-.33.044-.33" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "round", strokeLinejoin: "miter", strokeWidth: "0.265", d: "M6.879 4.212c.314.016.61.074.971-.019" }))));
}
