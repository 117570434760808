export var BattleBonusType;
(function (BattleBonusType) {
    BattleBonusType["GameDie"] = "gameDie";
    BattleBonusType["CombatEffectSymbol"] = "combatEffectSymbol";
})(BattleBonusType || (BattleBonusType = {}));
export var BattleBonusPurpose;
(function (BattleBonusPurpose) {
    BattleBonusPurpose["Attack"] = "attack";
    BattleBonusPurpose["Defense"] = "defense";
})(BattleBonusPurpose || (BattleBonusPurpose = {}));
export function createAttackGameDieBonus(gameDie) {
    return {
        type: BattleBonusType.GameDie,
        purpose: BattleBonusPurpose.Attack,
        gameDie: gameDie,
    };
}
export function createDefenseGameDieBonus(gameDie) {
    return {
        type: BattleBonusType.GameDie,
        purpose: BattleBonusPurpose.Defense,
        gameDie: gameDie,
    };
}
export function createSymbolBonus(purpose, symbol) {
    return {
        type: BattleBonusType.CombatEffectSymbol,
        purpose,
        symbol,
    };
}
