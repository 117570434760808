import React from 'react';
function UnitIconPikeman() {
    return (React.createElement("g", { transform: "translate(0 -285.09)" },
        React.createElement("rect", { y: "285.09", width: "57", height: "45", fill: "none" }),
        React.createElement("g", { transform: "translate(4 0)" },
            React.createElement("g", { stroke: "#000" },
                React.createElement("path", { d: "m28.135 316.4 0.89133 12.202h4.6623", fill: "none" }),
                React.createElement("path", { d: "m23.267 316.4-0.95989 12.07h-3.6339", fill: "none" }),
                React.createElement("rect", { x: "20.876", y: "302.32", width: "9.5082", height: "14.287", rx: ".21785", ry: ".20939", color: "#000000", fill: "#450", strokeLinecap: "round", strokeLinejoin: "round" })),
            React.createElement("g", { transform: "matrix(.19198 0 0 .18468 6.6282 263.48)", fill: "none", stroke: "#000", strokeWidth: "5.6649px" },
                React.createElement("path", { d: "m123.82 211.55c19.669 8.1251 17.454 18.511 29.286 72.857" }),
                React.createElement("path", { d: "m161.26 284.05c-10.833-18.742-20.714 2.4998-20.714 2.5" })),
            React.createElement("g", null,
                React.createElement("ellipse", { cx: "25.47", cy: "298.28", rx: "6.4157", ry: "6.172", color: "#000000", fill: "#e9c6af", stroke: "#000", strokeLinecap: "round", strokeLinejoin: "round" }),
                React.createElement("ellipse", { cx: "26.779", cy: "296.59", rx: ".5333", ry: ".83011", color: "#000000" }),
                React.createElement("ellipse", { cx: "24.161", cy: "296.59", rx: ".5333", ry: ".83011", color: "#000000" }),
                React.createElement("g", { stroke: "#000", strokeLinecap: "round", strokeLinejoin: "round" },
                    React.createElement("rect", { x: "20.831", y: "312.89", width: "9.5985", height: "3.7542", rx: ".21785", ry: ".20939", color: "#000000", fill: "#1a1a1a" }),
                    React.createElement("ellipse", { cx: "34.061", cy: "311.84", rx: "7.3363", ry: "7.0575", color: "#000000", fill: "#2b1100" }),
                    React.createElement("ellipse", { cx: "34.061", cy: "311.84", rx: "6.7272", ry: "6.4716", color: "#000000", fill: "#784421" }),
                    React.createElement("ellipse", { cx: "34.061", cy: "311.84", rx: "1.4302", ry: "1.3759", color: "#000000", fill: "#808080" }))),
            React.createElement("path", { d: "m20.969 302.69c-3.1289 2.3531-4.0717 3.7199-5.3283 8.7056-0.21773 0.86391 0.49174 1.9114 1.9077 1.904", fill: "none", stroke: "#000" }),
            React.createElement("path", { d: "m23.328 325.05-1.9413-0.19787-0.42974 2.7007c-1.9276-0.0716-3.6456-0.49522-3.8664 1.8073l6.4603-0.0466z", color: "#000000", fill: "#520", stroke: "#000", strokeLinecap: "round" }),
            React.createElement("g", { transform: "matrix(.19198 0 0 .13414 1.5771 278.89)", stroke: "#000", strokeLinecap: "round", strokeLinejoin: "round" },
                React.createElement("rect", { x: "82.196", y: "73.487", width: "3.8217", height: "304.54", rx: "1.1348", ry: "1.561", color: "#000000", fill: "#520", strokeWidth: "7.3577" }),
                React.createElement("path", { transform: "matrix(.76833 0 0 1.0661 19.265 -2.2689)", d: "m92.172 87.133-14.843 0.11844 6.5999-38.438z", color: "#000000", fill: "#b3b3b3", strokeWidth: "6.647" }),
                React.createElement("rect", { x: "82.201", y: "90.697", width: "3.9143", height: "15.657", rx: "1.1348", ry: "1.561", color: "#000000", fill: "#b3b3b3", strokeWidth: "6.647" })),
            React.createElement("g", { fill: "none", stroke: "#000" },
                React.createElement("path", { d: "m17.593 314.8c-3.5687-2.0487 0.53532-3.8187 0.53536-3.8188" }),
                React.createElement("path", { d: "m25.997 295.27 1.8666-0.0162" }),
                React.createElement("path", { d: "m24.81 295.25-1.8447 0.0242" })),
            React.createElement("g", { fill: "#502d16" },
                React.createElement("ellipse", { cx: "25.694", cy: "303.31", rx: ".39428", ry: ".39073", color: "#000000" }),
                React.createElement("path", { d: "m24.839 293.58c0.1317 0.5497 0.67597 0.99277 1.2725 0.97585 1.0722 0.0636 2.8951 0.51894 3.2811 1.2957 0.76376 0.97141 1.2944 2.3985 2.4253 2.8118 0.7399-0.011 0.27805-1.1787 0.17578-1.6598-0.22442-1.3478-1.0056-2.4348-1.8605-3.2805-0.53729-0.59528-1.2211-1.2419-2.0468-1.4307-0.88672-0.32086-1.826-0.29751-2.6751-0.31722-0.26547-0.0536-0.57738 0.0219-0.59299 0.44147 0.05437 0.56276 0.0038 0.80547 0.02068 1.1635z", fillRule: "evenodd", stroke: "#000" }),
                React.createElement("path", { d: "m24.873 291.97c0.36716-0.0242 0.21157 1.3897-0.20042 1.9541-0.46617 0.36816-1.1384 0.27682-1.6822 0.46921-0.70424 0.25159-0.66588 1.0789-0.96402 1.6294-0.27783 0.53709-0.47825 1.1441-1.0186 1.5053-0.71487 0.43128-0.65088 1.4185-1.3145 1.8817-0.90996 0.26772-1.1578-0.93579-0.92101-1.5524 0.39899-1.9178 0.67092-2.6507 1.9818-4.1081 1.2668-1.0752 3.6356-2.0073 4.119-1.7792z", fillRule: "evenodd", stroke: "#000" })),
            React.createElement("g", { fill: "none", stroke: "#000", strokeLinecap: "round" },
                React.createElement("path", { d: "m25.003 292.03c0.18374-0.55105 0.54674-0.50265 0.54674-0.50265" }),
                React.createElement("path", { d: "m25.001 292.1c-0.57418-0.75527-1.0286-0.67721-1.0286-0.67721" }),
                React.createElement("path", { d: "m25.015 292.13c-0.2915-0.78575-0.12146-0.94592-0.12146-0.94592" })),
            React.createElement("path", { d: "m27.731 325.05 1.9413-0.19787 0.42974 2.7007c1.9276-0.0716 3.6456-0.49522 3.8664 1.8073l-6.4603-0.0466z", color: "#000000", fill: "#520", stroke: "#000", strokeLinecap: "round" }),
            React.createElement("g", { transform: "matrix(.19198 0 0 .13414 1.5771 278.89)" },
                React.createElement("rect", { x: "82.196", y: "73.487", width: "3.8217", height: "304.54", rx: "1.1348", ry: "1.561", color: "#000000", fill: "#520" }),
                React.createElement("path", { transform: "matrix(.76833 0 0 1.0661 19.265 -2.2689)", d: "m92.172 87.133-14.843 0.11844 6.5999-38.438z", color: "#000000", fill: "#b3b3b3" }),
                React.createElement("rect", { x: "82.201", y: "90.697", width: "3.9143", height: "15.657", rx: "1.1348", ry: "1.561", color: "#000000", fill: "#b3b3b3" })),
            React.createElement("g", { fill: "#502d16" },
                React.createElement("ellipse", { cx: "27.344", cy: "302.79", rx: ".39428", ry: ".39073", color: "#000000" }),
                React.createElement("ellipse", { cx: "24.308", cy: "302.55", rx: ".39428", ry: ".39073", color: "#000000" }),
                React.createElement("ellipse", { cx: "22.076", cy: "302.3", rx: ".39428", ry: ".39073", color: "#000000" }),
                React.createElement("ellipse", { cx: "22.277", cy: "300.32", rx: ".39428", ry: ".39073", color: "#000000" }),
                React.createElement("ellipse", { cx: "20.893", cy: "300.38", rx: ".39428", ry: ".39073", color: "#000000" }),
                React.createElement("ellipse", { cx: "29.308", cy: "300.65", rx: ".39428", ry: ".39073", color: "#000000" }),
                React.createElement("ellipse", { cx: "28.884", cy: "301.97", rx: ".39428", ry: ".39073", color: "#000000" }),
                React.createElement("ellipse", { cx: "30.424", cy: "299.92", rx: ".39428", ry: ".39073", color: "#000000" }),
                React.createElement("ellipse", { cx: "25.759", cy: "302.08", rx: ".39428", ry: ".39073", color: "#000000" }),
                React.createElement("ellipse", { cx: "23.393", cy: "302.97", rx: ".39428", ry: ".39073", color: "#000000" }),
                React.createElement("ellipse", { cx: "23.103", cy: "301.48", rx: ".39428", ry: ".39073", color: "#000000" }),
                React.createElement("ellipse", { cx: "21.54", cy: "301.32", rx: ".39428", ry: ".39073", color: "#000000" }),
                React.createElement("ellipse", { cx: "28.17", cy: "299.8", rx: ".39428", ry: ".39073", color: "#000000" })),
            React.createElement("path", { d: "m23.411 300.56c1.6292 0.52545 2.2393 0.54829 4.1884-0.0223", fill: "none", stroke: "#000", strokeLinecap: "round", strokeWidth: "1px" }),
            React.createElement("ellipse", { cx: "27.679", cy: "301.68", rx: ".39428", ry: ".39073", color: "#000000", fill: "#502d16" }))));
}
export default UnitIconPikeman;
