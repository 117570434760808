import React from 'react';
function Arrow({ angle, tipPoint, baseSize, offset = 0, style = {}, ...restProps }) {
    const lengthA = Math.round(baseSize / 2);
    const lengthB = Math.round(baseSize / 5);
    const lengthC = Math.round(baseSize - baseSize / 10);
    const strokeWidth = Math.ceil(baseSize / 30);
    const tip = {
        ...tipPoint,
        x: tipPoint.x - offset,
    };
    const arrowStyle = {
        fill: '#38c138',
        stroke: 'black',
        strokeWidth,
        ...style,
    };
    return (React.createElement("g", { transform: `rotate(${angle} ${tipPoint.x} ${tipPoint.y})`, ...restProps },
        React.createElement("path", { className: "arrow", d: `
                    M ${tip.x} ${tip.y}
                    L ${tip.x - lengthA} ${tip.y + lengthA}
                    L ${tip.x - lengthA} ${tip.y + lengthB}
                    L ${tip.x - lengthC} ${tip.y + lengthB}
                    L ${tip.x - lengthC} ${tip.y - lengthB}
                    L ${tip.x - lengthA} ${tip.y - lengthB}
                    L ${tip.x - lengthA} ${tip.y - lengthA}
                    Z
                `, style: arrowStyle })));
}
export default Arrow;
