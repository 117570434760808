import React from 'react';
export default function Swordman() {
    return (React.createElement("g", null,
        React.createElement("g", { strokeDasharray: "none", strokeMiterlimit: "4" },
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M28.96 31.797l.87 12.402h4.56" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M24.2 31.797l-.939 12.268h-3.553" }),
            React.createElement("rect", { width: "9.297", height: "14.52", x: "21.847", y: "17.502", fill: "#540", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", rx: "0.213", ry: "0.213", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M31.172 17.72c3.954 1.889 8.532 4.76 3.695 11.163" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M36.07 30.175c1.192-3.885-2.991-2.53-2.991-2.53" }),
            React.createElement("rect", { width: "9.385", height: "3.816", x: "21.803", y: "28.251", fill: "#1a1a1a", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", rx: "0.213", ry: "0.213", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("g", { fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "5.328", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", imageRendering: "auto", shapeRendering: "auto", textRendering: "auto", transform: "matrix(.1877 0 0 .1877 .1 -26.723)", visibility: "visible" },
                React.createElement("circle", { cx: "174.63", cy: "282.247", r: "38.214", fill: "#2b1100", display: "inline", enableBackground: "accumulate", opacity: "1", overflow: "visible" }),
                React.createElement("circle", { cx: "174.63", cy: "282.247", r: "35.042", fill: "#784421", display: "inline", enableBackground: "accumulate", opacity: "1", overflow: "visible" }),
                React.createElement("circle", { cx: "174.63", cy: "282.247", r: "7.45", fill: "gray", display: "inline", enableBackground: "accumulate", opacity: "1", overflow: "visible" })),
            React.createElement("g", { fillOpacity: "1", fillRule: "nonzero", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", imageRendering: "auto", shapeRendering: "auto", textRendering: "auto", visibility: "visible" },
                React.createElement("path", { fill: "#b3b3b3", stroke: "#000", d: "M32.565 21.687l1.42.172-.573 1.31-11.762 6.794-.846-1.482z", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                React.createElement("path", { fill: "#520", stroke: "#2b1100", d: "M35.145 -3.164H36.592000000000006V0.5329999999999999H35.145z", display: "inline", enableBackground: "accumulate", overflow: "visible", transform: "rotate(60.272)" }),
                React.createElement("path", { fill: "#999", stroke: "#000", d: "M33.309 -4.097H38.428V-2.865H33.309z", display: "inline", enableBackground: "accumulate", overflow: "visible", transform: "rotate(60.272)" })),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M22.277 17.597c-3.692 1.525-6.026 10.158-3.743 12.775" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M17.645 31.93c-1.444-3.798 2.819-2.72 2.819-2.72" }),
            React.createElement("path", { fill: "#520", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M28.798 40.525l1.898-.201.42 2.745c1.885-.073 3.565-.504 3.78 1.836l-6.316-.047z", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { d: "M24.254 40.525l-1.898-.201-.42 2.745c-1.885-.073-3.564-.504-3.78 1.836l6.316-.047z", fill: "#520", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("g", { strokeLinecap: "round", strokeOpacity: "1", strokeWidth: "5.328", transform: "matrix(.1877 0 0 .1877 -29.155 -22.557)" },
                React.createElement("circle", { cx: "295.722", cy: "191.46", r: "33.419", fill: "#e9c6af", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinejoin: "round", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", opacity: "1", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
                React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinejoin: "miter", d: "M283.165 204.43c.719 3.292 24.631 4.153 24.465.357" }),
                React.createElement("ellipse", { cx: "304.733", cy: "183.792", fill: "#000", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinejoin: "round", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", opacity: "1", overflow: "visible", rx: "2.778", ry: "4.495", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
                React.createElement("ellipse", { cx: "286.033", cy: "184.264", fill: "#000", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinejoin: "round", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", opacity: "1", overflow: "visible", rx: "2.776", ry: "4.495", shapeRendering: "auto", textRendering: "auto", visibility: "visible" })),
            React.createElement("g", { fill: "gray", strokeWidth: "5.328", transform: "matrix(.1877 0 0 .1877 -33.24 -36.18)" },
                React.createElement("path", { d: "M354.827 255.362c-.067 1.116-.013 10.657-.013 10.657l-8.527.168s-1.724-13.515-2.015-13.764c-7.023-6-12.099-6.602-24.023-6.857-.294-.006-.777 16.025-1.071 16.022-1.057-.01-2.935.063-3.99.096-.25.008-.814-16.273-1.063-16.263-12.128.486-18.916 1.796-25.542 7.642-.255.225-.858 13.684-1.25 13.663l-8.002-.412-.008-10.951c4.105-23.112 30.373-37.705 37.752-37.752 7.298-.048 34.265 15.103 37.752 37.752z", fill: "gray", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDasharray: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: "4", strokeOpacity: "1", strokeWidth: "5.328", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", opacity: "1", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" })))));
}
