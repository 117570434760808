import React from 'react';
export default function HealthSymbol({ healthPoints, size, position, }) {
    const heartWidth = size;
    const heartHeight = heartWidth;
    const heartRadius = Math.round(heartWidth / 10);
    const heartCoor = {
        x: position.x,
        y: position.y + heartRadius,
    };
    const idFontSize = heartHeight * 1.1;
    return (React.createElement(React.Fragment, null,
        React.createElement("path", { d: `M ${heartCoor.x},${heartCoor.y}
                    A ${heartRadius},${heartRadius} 0,0,1 ${heartCoor.x + heartWidth / 2},${heartCoor.y}
                    A ${heartRadius},${heartRadius} 0,0,1 ${heartCoor.x + heartWidth},${heartCoor.y}
                    Q ${heartCoor.x + heartWidth},${heartCoor.y + (heartHeight * 1) / 3} ${heartCoor.x + heartWidth / 2},${heartCoor.y + (heartHeight * 2) / 3}
                    Q ${heartCoor.x},${heartCoor.y + (heartHeight * 1) / 3} ${heartCoor.x},${heartCoor.y}
                    Z`, fill: "rgb(242, 75, 75)", stroke: "rgb(185, 75, 75)", strokeWidth: "1" }),
        React.createElement("text", { x: position.x + heartWidth / 2, y: position.y + (heartHeight * 3) / 5, fontFamily: "Verdana", fontWeight: "bold", fontSize: idFontSize, textAnchor: "middle" }, healthPoints)));
}
