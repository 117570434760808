import React from 'react';
export default function MediumCavalry() {
    return (React.createElement("g", null,
        React.createElement("g", { strokeDasharray: "none", strokeMiterlimit: "4" },
            React.createElement("g", { strokeLinejoin: "round", strokeWidth: "7.731", transform: "matrix(.12932 0 0 .12938 -104.78 -96.996)" },
                React.createElement("rect", { width: "3.822", height: "221.188", x: "74.287", y: "1173.493", fill: "#520", fillOpacity: "1", fillRule: "nonzero", stroke: "#520", strokeDasharray: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeMiterlimit: "4", strokeOpacity: "1", strokeWidth: "7.731", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", opacity: "1", overflow: "visible", rx: "2.148", ry: "2.146", shapeRendering: "auto", textRendering: "auto", transform: "rotate(-45.876)", visibility: "visible" }),
                React.createElement("path", { d: "M908.515 766.681l-7.874 8.25-17.834-24.36z", fill: "#000", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDasharray: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeMiterlimit: "4", strokeOpacity: "1", strokeWidth: "7.731", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", opacity: "1", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
                React.createElement("rect", { width: "3.914", height: "11.372", x: "74.292", y: "1185.992", fill: "#b3b3b3", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDasharray: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeMiterlimit: "4", strokeOpacity: "1", strokeWidth: "7.731", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", opacity: "1", overflow: "visible", rx: "2.148", ry: "2.146", shapeRendering: "auto", textRendering: "auto", transform: "rotate(-45.876)", visibility: "visible" }),
                React.createElement("path", { fill: "#ccc", stroke: "none", strokeLinecap: "square", strokeWidth: "1", d: "M6.558-2.193L5.539-1.125 3.233-4.277z", transform: "matrix(7.73251 0 0 7.72931 857.809 783.629)" })),
            React.createElement("path", { fill: "#d38d5f", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M13.404 14.918c-.821-.683-1.67-1.446-1.225-2.354.62.422 1.936.722 2.306 1.233.174.428-.724.973-1.08 1.121z" }),
            React.createElement("path", { fill: "#f95", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M13.681 13.823c.054-.757.196-1.36.857-1.902.604-.565.722.133 1.494-.027.773-.044 1.545.946 2.124 1.334.846.242.807.76 1.632 1.174.875.474 1.27.8 1.703 1.703.54.779 1.341 1.096 1.98 1.763.322.289 1.449.06 1.302.636-.357.317-.183.692-.04 1.216.113.39.044.774-.49.534-.72-.311-1.308-.861-2.048-1.132-.932-.403-1.966-.725-2.618-1.559-.573-.672-1.113-1.417-1.927-1.816-.811-.53-1.423-1.386-2.362-1.704-.531-.095-1.067-.196-1.607-.22z" }),
            React.createElement("path", { fill: "#f95", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M42.888 20.064c.924.142.719-.482 2.252-.419 3.472.094 2.412 6.095 1.89 7.182-.671 2.122 1.583 6.124-.016 6.655-1.211.059-1.915-.459-2.365-1.517-.281-1.01.201-2.02.216-3.034-.067-1.363-.53-2.899-.173-3.816 1.885-2.825-1.97-2.803-1.84-3.684.048-.206.129-1.369.048-1.19l-.019-.057z" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M25.286 30.147l-2.964 6.195.793 5.264" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M39.65 31.163l1.312 6.228-2.005 4.816" }),
            React.createElement("path", { d: "M15.42 13.413c4.429 4.052 6.681 5.98 9.581 6.736 5.072 1.321 11.508-.211 17.74-.657 1.618 13 1.575 12.93-16.678 11.354-2.444-.107-5.309-3.619-9.486-8.494-1.292 1.872-2.83 3.428-4.31 3.454-4.847-1.144-2.892-11.734 3.153-12.393z", fill: "#c87137", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "#d38d5f", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M16.333 14.772c.78-.742 1.585-1.57 1.163-2.556-.59.458-1.836.784-2.188 1.339-.165.464.687 1.056 1.025 1.217z" }),
            React.createElement("ellipse", { cx: "13.614", cy: "16.613", fill: "#000", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", rx: "0.31", ry: "0.703", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M11.346 21.567c-1.226 1.228.175 1.696.175 1.696" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "round", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M9.552 19.713s5.138.117 5.838 4.443" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "round", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M10.048 18.307s5.254.351 5.955 4.677" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M14.748 20.356s-.875.234-.934.76" }),
            React.createElement("path", { d: "M26.745 19.859c.373-1.108.276-1.157.557-1.22.825-.182.915-.191.815 1.22 1.967.457 3.576.316 5.08 0 .363-1.28.852-1.315.852-1.315.696.003.46.074.578 1.315.778 10.538-10.555 6.361-7.882 0z", fill: "#a40", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("ellipse", { cx: "12.281", cy: "16.613", fill: "#000", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", rx: "0.31", ry: "0.703", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M15.956 22.985c6.053-.206 9.342-2.712 9.976-3.928 2.625-5.027-4.215-2.873-4.341-2.772" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M27.148 10.678c.505 2.668 1.946 2.857-2.355 5.082" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M24.46 14.344c2.743.555.547 2.643.547 2.643" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M30.568 20.381l-.647 8.456h-2.448" }),
            React.createElement("path", { d: "M30.61 26.442l-1.309-.138-.29 1.892c-1.298-.05-2.455-.347-2.604 1.266l4.352-.033z", fill: "#520", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("rect", { width: "6.405", height: "10.008", x: "22.691", y: "17.233", fill: "#6f916f", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", imageRendering: "auto", overflow: "visible", rx: "0.278", ry: "0.278", color: "#000", display: "inline", enableBackground: "accumulate", shapeRendering: "auto", textRendering: "auto", transform: "rotate(-13.612) skewX(-.011)", visibility: "visible" }),
            React.createElement("rect", { width: "6.466", height: "2.63", x: "22.661", y: "24.641", fill: "#1a1a1a", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", imageRendering: "auto", overflow: "visible", rx: "0.278", ry: "0.278", color: "#000", display: "inline", enableBackground: "accumulate", shapeRendering: "auto", textRendering: "auto", transform: "rotate(-13.612) skewX(-.011)", visibility: "visible" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M25.841 16.102c2.427 1.396-.317 2.68-.317 2.68" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M32.106 10.22c2.543 1.052-1.506 7.473-6.304 7.25" }),
            React.createElement("path", { fill: "#5d6c53", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M40.66 17.55l-1.23.52-.617.827-1.453.626-.738.972-.919.237-.356.657-.777.071-.436.85c-.39-.49-.425-1.216-.772-1.669-4.09-5.349-3.943-7.492-4.624-7.721-2.135-.718-2.434-2.285 3.322-3.345.733-.135 1.98 1.808 2.831 2.462 1.176.904 2.748 1.917 3.765 3.715.367.647 1.69 1.028 2.003 1.798z", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("ellipse", { cx: "28.025", cy: "7.222", fill: "#e9c6af", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", rx: "4.322", ry: "4.324", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("ellipse", { cx: "26.77", cy: "6.397", fill: "#000", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", rx: "0.359", ry: "0.582", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("ellipse", { cx: "25.007", cy: "6.397", fill: "#000", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", rx: "0.359", ry: "0.582", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "#520", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M24.234 42.57l-2.456.01c.683-2.004 2.4-1.968 2.456-.01z", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M26.025 30.47l-1.342 7.126 3.105 4.318" }),
            React.createElement("path", { d: "M29.225 41.599l-1.237 2.123c-1.38-1.604-.48-3.067 1.237-2.123z", fill: "#520", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M40.481 30.332l3.203 6.513-2.14 6.84" }),
            React.createElement("path", { fill: "#520", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M39.512 43.647l-2.247-.991c1.438-1.553 2.992-.822 2.247.99z", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { d: "M42.55 44.868l-2.457.009c.683-2.004 2.4-1.968 2.456-.009z", fill: "#520", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M25.159 9.431c.679.008 1.24-.06 2.457-.679" }),
            React.createElement("path", { fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M32.624 8.386l-4.435-.73c.01-2.865-.44-2.87-1.91-2.902-.062-.02-.102 1.763-.257 2.475l-.486.033c.03-.239-.134-2.483-.184-2.5-1.384-.445-1.203 1.703-1.403 2.596l-.722-.19c.362-4.223 3.24-4.907 4.527-5.338 2.985.531 4.511 2.248 5.035 4.363-.002.766-.112 1.441-.165 2.193z", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { d: "M40.66 17.55l-1.23.52-.617.827-1.453.626-.738.972-.919.237-.356.657-.777.071-.436.85c-.19-.237-.295-.53-.393-.82.2-.148.295-.55.482-.69.16-.12.412.024.564-.089.175-.13.167-.498.331-.62.288-.212.739-.17.998-.361.305-.224.446-.627.719-.826.478-.348 1.058-.469 1.465-.764.276-.2.492-.683.75-.87.42-.075.686-.176 1-.408.27.2.5.42.61.688z", fill: "#454837", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }))));
}
