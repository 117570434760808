import React from 'react';
import PlainHex, { graphicalVariants as plainVariants } from '@wareena/ui/game/terrain/PlainHex';
import WoodHex, { graphicalVariants as woodVariants } from '@wareena/ui/game/terrain/WoodHex';
import RiverHex, { graphicalVariants as riverVariants } from '@wareena/ui/game/terrain/RiverHex';
import HillHex, { graphicalVariants as hillVariants } from '@wareena/ui/game/terrain/HillHex';
import { TerrainId } from '@wareena/grid-battles-entity/game/map/terrain';
import TerrainWithBonuses from './TerrainWithBonuses.js';
import BridgeHex, { graphicalVariants as bridgeVariants, } from '@wareena/ui/game/terrain/Bridge/index';
const terrainMap = {
    [TerrainId.Plain]: withBonuses(TerrainId.Plain, PlainHex),
    [TerrainId.Wood]: withBonuses(TerrainId.Wood, WoodHex),
    [TerrainId.River]: withBonuses(TerrainId.River, RiverHex),
    [TerrainId.Hill]: withBonuses(TerrainId.Hill, HillHex),
    [TerrainId.Bridge]: withBonuses(TerrainId.Bridge, BridgeHex),
};
const graphicalVariantsMap = {
    [TerrainId.Plain]: plainVariants,
    [TerrainId.Wood]: woodVariants,
    [TerrainId.River]: riverVariants,
    [TerrainId.Hill]: hillVariants,
    [TerrainId.Bridge]: bridgeVariants,
};
function withBonuses(terrainId, TerrainHex) {
    return function (props) {
        return React.createElement(TerrainWithBonuses, { terrainHex: TerrainHex, terrainId: terrainId, ...props });
    };
}
export function getTerrainHex(terrainId) {
    return terrainMap[terrainId] || null;
}
export function getGraphicalVariants(terrainId) {
    return graphicalVariantsMap[terrainId] || [];
}
export default terrainMap;
