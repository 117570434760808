import React from 'react';
import UnitIconArcher from './UnitIconArcher.js';
import UnitIconPikeman from './UnitIconPikeman.js';
import UnitIconKnight from './UnitIconKnight.js';
import UnitIconLongbow from './UnitIconLongbow.js';
import UnitIconCrossbow from './UnitIconCrossbow.js';
import Swordman from './Swordman.js';
import Axeman from './Axeman.js';
import Maceman from './Maceman.js';
import LightCavalry from './LightCavalry.js';
import MediumCavalry from './MediumCavalry.js';
function UnitIcon({ iconName }) {
    const unitIcons = {
        archer: UnitIconArcher,
        longbow: UnitIconLongbow,
        crossbow: UnitIconCrossbow,
        pikeman: UnitIconPikeman,
        swordman: Swordman,
        axeman: Axeman,
        maceman: Maceman,
        lightCavalry: LightCavalry,
        mediumCavalry: MediumCavalry,
        knight: UnitIconKnight,
    };
    if (unitIcons[iconName] === undefined) {
        return null;
    }
    const Icon = unitIcons[iconName];
    return React.createElement(Icon, null);
}
export default UnitIcon;
