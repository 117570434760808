import React from 'react';
import { SIDE_A, SIDE_B, sideIds } from '@wareena/grid-battles-entity/game/codebook/side';
import UnitIcon from '../icons/index.js';
import DieIcon from '../dice/DieIcon.js';
import MovementSymbol from './MovementSymbol.js';
import HealthSymbol from './HealthSymbol.js';
const sideAColor = '#449ee7';
const sideBColor = '#d55d14';
const nameColors = {
    [sideIds[SIDE_A]]: sideAColor,
    [sideIds[SIDE_B]]: sideBColor,
};
function UnitCounter({ counterSize, origin, unit, containerProps = {}, bgColor = 'white', isBold = false, }) {
    const pixelSize = counterSize / 60;
    const canonicalSize = 57.735026918962575;
    const radius = Math.floor(counterSize / 10);
    const halfSize = counterSize / 2;
    const quarterSize = counterSize / 4;
    const nameColor = nameColors[unit.side];
    const idFontSize = Math.round((quarterSize * 2) / 3);
    const diceSize = counterSize / 8;
    const dicePosition = {
        x: origin.x + counterSize - diceSize * 1.5,
        yAttack: origin.y + quarterSize + diceSize / 2,
        yDefense: origin.y + counterSize - diceSize * 1.5,
    };
    const movementCoor = {
        x: origin.x + 4 * pixelSize,
        y: origin.y + quarterSize + 2 * pixelSize,
    };
    const healthCoor = {
        x: origin.x + counterSize / 16,
        y: origin.y + counterSize - counterSize / 6 - pixelSize,
    };
    return (React.createElement("g", { ...containerProps, style: {
            ...(containerProps.style ?? {}),
            filter: 'drop-shadow(1px 1px 2px rgba(0, 0, 0, .7))',
        } },
        React.createElement("g", null,
            React.createElement("path", { d: `M ${origin.x} ${origin.y + quarterSize}
                        L ${origin.x} ${origin.y + radius}
                        C ${origin.x},${origin.y} ${origin.x},${origin.y} ${origin.x + radius},${origin.y}
                        L ${origin.x + counterSize - radius} ${origin.y}
                        C ${origin.x + counterSize},${origin.y} ${origin.x + counterSize},${origin.y} ${origin.x + counterSize},${origin.y + radius}
                        L ${origin.x + counterSize} ${origin.y + quarterSize}
                        Z`, fill: nameColor, stroke: "none", strokeWidth: "1" }),
            React.createElement("line", { x1: origin.x, y1: origin.y + quarterSize, x2: origin.x + counterSize, y2: origin.y + quarterSize, stroke: "black", strokeWidth: "2" }),
            React.createElement("text", { x: origin.x + halfSize, y: origin.y + quarterSize - ((quarterSize - idFontSize) / 2 + 1), fontFamily: "Verdana", fontSize: idFontSize, textAnchor: "middle" }, unit.label)),
        React.createElement("path", { d: `M ${origin.x} ${origin.y + quarterSize}
                    L ${origin.x} ${origin.y + counterSize - radius}
                    C ${origin.x},${origin.y + counterSize} ${origin.x},${origin.y + counterSize} ${origin.x + radius},${origin.y + counterSize}
                    L ${origin.x + counterSize - radius} ${origin.y + counterSize}
                    C ${origin.x + counterSize},${origin.y + counterSize} ${origin.x + counterSize},${origin.y + counterSize} ${origin.x + counterSize},${origin.y + counterSize - radius}
                    L ${origin.x + counterSize} ${origin.y + quarterSize}
                    Z`, fill: bgColor, stroke: "none", strokeWidth: "1" }),
        isBold ? (React.createElement("path", { d: `M ${origin.x} ${origin.y + quarterSize * 3}
                        l ${counterSize} 0
                        L ${origin.x + counterSize} ${origin.y + counterSize - radius}
                        c 0 ${radius} 0 ${radius} ${-1 * radius} ${radius}
                        L ${origin.x + radius} ${origin.y + counterSize}
                        c ${-1 * radius} 0 ${-1 * radius} 0 ${-1 * radius} ${-1 * radius}
                        Z
                    `, fill: "lightblue", stroke: "black", strokeWidth: 0 })) : null,
        React.createElement(MovementSymbol, { movementPoints: unit.movementPoints, position: movementCoor, counterSize: counterSize }),
        React.createElement(HealthSymbol, { healthPoints: unit.health, position: healthCoor, size: counterSize / 6 }),
        unit.attackDice.map((dieType, idx) => {
            return (React.createElement(DieIcon, { type: dieType, origin: {
                    x: dicePosition.x,
                    y: dicePosition.yAttack + diceSize * idx * 1.5,
                }, size: diceSize, key: `atk-die-${idx}` }));
        }),
        unit.defenseDice.map((dieType, idx) => {
            return (React.createElement(DieIcon, { type: dieType, origin: {
                    x: dicePosition.x,
                    y: dicePosition.yDefense - diceSize * idx * 1.5,
                }, size: diceSize, key: `def-die-${idx}` }));
        }),
        React.createElement("g", { transform: `translate(${origin.x} ${origin.y + counterSize / 6}) scale(${counterSize / canonicalSize})` },
            React.createElement(UnitIcon, { iconName: unit.iconName })),
        React.createElement("path", { d: `M ${origin.x} ${origin.y + radius}
                    C ${origin.x},${origin.y} ${origin.x},${origin.y} ${origin.x + radius},${origin.y}
                    L ${origin.x + counterSize - radius} ${origin.y}
                    C ${origin.x + counterSize},${origin.y} ${origin.x + counterSize},${origin.y} ${origin.x + counterSize},${origin.y + radius}
                    L ${origin.x + counterSize} ${origin.y + counterSize - radius}
                    C ${origin.x + counterSize},${origin.y + counterSize} ${origin.x + counterSize},${origin.y + counterSize} ${origin.x + counterSize - radius},${origin.y + counterSize}
                    L ${origin.x + radius} ${origin.y + counterSize}
                    C ${origin.x},${origin.y + counterSize} ${origin.x},${origin.y + counterSize} ${origin.x},${origin.y + counterSize - radius}
                    Z`, fill: "none", stroke: "black", strokeWidth: 1 })));
}
export default UnitCounter;
