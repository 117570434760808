import React from 'react';
function UnitIconArcher() {
    return (React.createElement("g", { transform: "translate(0 -285.09)" },
        React.createElement("rect", { y: "285.09", width: "57", height: "45", fill: "none" }),
        React.createElement("g", { transform: "translate(5 0)" },
            React.createElement("g", { stroke: "#000" },
                React.createElement("path", { d: "m20.927 295.79c9.7195-0.4422 5.4191 13.1 6.2914 23.169 0 0-1.9605-0.81236-2.9943-0.89364-1.5476-0.12168-3.0667 0.61764-4.6185 0.62218-1.3607 4e-3 -2.6924-0.56282-4.0526-0.5219-1.0226 0.0308-3.0212 0.57497-3.0212 0.57497 2.8881-9.0228-3.4729-24.315 8.3952-22.951z", color: "#000000", fill: "#668000", strokeLinecap: "round", strokeLinejoin: "round" }),
                React.createElement("path", { d: "m23.976 314.28 0.99812 14.284h5.2209", fill: "none" }),
                React.createElement("path", { d: "m18.211 314.28-1.0967 13.966h4.0693", fill: "none" })),
            React.createElement("g", { stroke: "#000", strokeLinecap: "round", strokeLinejoin: "round" },
                React.createElement("rect", { x: "15.83", y: "297.81", width: "10.647", height: "16.724", rx: ".24395", ry: ".24512", color: "#000000", fill: "#2c89a0" }),
                React.createElement("rect", { x: "15.78", y: "310.19", width: "10.749", height: "4.3948", rx: ".24395", ry: ".24512", color: "#000000", fill: "#1a1a1a" }),
                React.createElement("ellipse", { cx: "20.991", cy: "293.06", rx: "7.1845", ry: "7.225", color: "#000000", fill: "#e9c6af" })),
            React.createElement("path", { d: "m15.671 298.14c-4.2285 1.7566-9.4723 3.9246 9.2518 5.5592", fill: "none", stroke: "#000" }),
            React.createElement("path", { d: "m25.784 305.87c-3.9196-2.523 0.20097-4.372 0.20101-4.372", fill: "none", stroke: "#000" }),
            React.createElement("g", null,
                React.createElement("ellipse", { cx: "25.662", cy: "291.52", rx: ".76181", ry: "1.2396", color: "#000000" }),
                React.createElement("ellipse", { cx: "22.401", cy: "291.52", rx: ".76181", ry: "1.2396", color: "#000000" }),
                React.createElement("g", { stroke: "#000", strokeLinecap: "round" },
                    React.createElement("path", { d: "m24.149 324.48 2.174-0.23164 0.48123 3.1615c2.1585-0.0838 4.0824-0.57971 4.3297 2.1156l-7.2344-0.0546z", color: "#000000", fill: "#520" }),
                    React.createElement("path", { d: "m16.09 324.42 2.174-0.23163 0.48123 3.1615c2.1585-0.0838 4.0824-0.5797 4.3297 2.1156l-7.2344-0.0546z", color: "#000000", fill: "#520" }),
                    React.createElement("path", { d: "m28.67 315.27c3.9972-3.7693 5.7598-9.0744 6.1678-13.442 0.3353-5.0041-2.1951-8.8101-4.299-13.042 3.3135 2.4773 6.374 7.5298 5.9927 13.23-0.43899 6.414-3.6567 11.839-7.8615 13.254z", color: "#000000", fill: "#803300", strokeLinejoin: "round" }))),
            React.createElement("path", { d: "m35.885 301.74c-4.4651 1.3192-0.52909 4.4792-0.52906 4.4793", fill: "none", stroke: "#000" }),
            React.createElement("path", { d: "m30.688 289-5.7513 14.438 4.052 11.589", fill: "none", stroke: "#000", strokeWidth: "1px" }),
            React.createElement("g", null,
                React.createElement("rect", { transform: "matrix(.99679 .08005 -.07916 .99686 0 0)", x: "48.897", y: "300.19", width: "18.025", height: ".60054", rx: "0", ry: "0", color: "#000000", fill: "#803300" }),
                React.createElement("path", { d: "m42.849 305.81 0.13875-1.7472 2.377 0.89581z", color: "#000000", fill: "#b3b3b3", stroke: "#b3b3b3", strokeLinecap: "round", strokeLinejoin: "round" }),
                React.createElement("path", { d: "m24.942 302.06 2.1525 0.17287 0.27146 1.0675-2.0856-0.16748z", color: "#000000", fill: "#f9f9f9" }),
                React.createElement("path", { d: "m24.727 304.77 2.1525 0.17287 0.43648-1.0106-2.0856-0.16749z", color: "#000000", fill: "#f9f9f9" })),
            React.createElement("path", { d: "m26.37 298.07c2.817 4.8918 6.1218 5.5417 8.1436 5.7872", fill: "none", stroke: "#000" }),
            React.createElement("path", { d: "m27.398 289.67c-1.0569-0.48816-2.1738-0.92373-3.3721-0.57476-1.335-0.21439-1.8294-0.15905-3.0934-0.0129-2.2228 0.4699-2.7965 3.6794-2.1682 4.8781 0.13256 1.1799-0.60654 1.6285-0.88241 2.6928 0.22938 1.1774-1.0973 2.2531-2.2708 1.6004-3.1563-0.0884-2.6368-2.0681-2.1522-3.5524-0.43103-3.5507 1.1123-5.0724 1.803-6.4607 0.44435-1.0507 1.9913-1.6053 2.8052-2.0907 0.95041 0.0812 1.7899-0.1935 2.7111-0.38415 1.169-0.23983 2.195-0.0241 3.3805 0.41896 1.1214-0.0377 2.0314 0.31601 2.5794 1.167 0.86902 1.1886 1.039 0.96959 0.65992 2.3184z", fill: "#a05a2c", fillRule: "evenodd", stroke: "#000" }),
            React.createElement("g", { fill: "none", stroke: "#000" },
                React.createElement("path", { d: "m24.518 289.91 1.6123-0.36676" }),
                React.createElement("path", { d: "m23.232 289.97-1.8043-0.48257" }),
                React.createElement("path", { d: "m21.71 295.56c2.3921 0.46566 2.6784 0.451 4.2982 0.11228", strokeLinecap: "round", strokeWidth: "1px" })),
            React.createElement("path", { d: "m22.005 296.14 1.8686 0.26203c-0.20342 0.97008-1.8686 1.1045-1.8686-0.26203z", color: "#000000", fill: "#a00" }))));
}
export default UnitIconArcher;
