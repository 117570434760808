import React from 'react';
import { AttackDieType, DefenseDieType, } from '@wareena/grid-battles-entity/game/codebook/dice';
import { hexCorner } from '@wareena/game-algorithms';
function HexDieSymbol({ origin, size, fill, stroke = '#000', }) {
    const center = {
        x: origin.x + size / 2,
        y: origin.y + size / 2,
    };
    const hexSideSize = (size * 3) / 5;
    const pointsLeft = [
        hexCorner(center, hexSideSize, 1, false),
        hexCorner(center, hexSideSize, 2, false),
        hexCorner(center, hexSideSize, 3, false),
        center,
    ];
    const pointsRight = [
        hexCorner(center, hexSideSize, 5, false),
        hexCorner(center, hexSideSize, 0, false),
        hexCorner(center, hexSideSize, 1, false),
        center,
    ];
    const pointsTop = [
        hexCorner(center, hexSideSize, 3, false),
        hexCorner(center, hexSideSize, 4, false),
        hexCorner(center, hexSideSize, 5, false),
        center,
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement("polygon", { points: pointsLeft.map((point) => `${point.x} ${point.y}`).join(', '), stroke: stroke, strokeWidth: "1", fill: fill.left, strokeLinejoin: "round" }),
        React.createElement("polygon", { points: pointsRight.map((point) => `${point.x} ${point.y}`).join(', '), stroke: stroke, strokeWidth: "1", fill: fill.right, strokeLinejoin: "round" }),
        React.createElement("polygon", { points: pointsTop.map((point) => `${point.x} ${point.y}`).join(', '), stroke: stroke, strokeWidth: "1", fill: fill.top, strokeLinejoin: "round" })));
}
function AttackDieYellow({ origin, size }) {
    return (React.createElement(HexDieSymbol, { origin: origin, size: size, fill: { left: '#ffa', right: '#cc0', top: '#ff0' } }));
}
function AttackDieBlue({ origin, size }) {
    return (React.createElement(HexDieSymbol, { origin: origin, size: size, fill: { left: '#009fff', right: '#002fff', top: '#007fff' } }));
}
function AttackDieRed({ origin, size }) {
    return (React.createElement(HexDieSymbol, { origin: origin, size: size, fill: { left: '#ff3333', right: '#cc0000', top: '#ff0000' } }));
}
function DefenseDieBrown({ origin, size }) {
    return (React.createElement(HexDieSymbol, { origin: origin, size: size, fill: { left: '#984421', right: '#584421', top: '#784421' } }));
}
function DefenseDieGrey({ origin, size }) {
    return (React.createElement(HexDieSymbol, { origin: origin, size: size, fill: { left: '#c8beb7', right: '#a89e97', top: '#d8cec7' } }));
}
function DefenseDieBlack({ origin, size }) {
    return (React.createElement(HexDieSymbol, { origin: origin, size: size, fill: { left: '#555', right: '#333', top: '#444' }, stroke: "black" }));
}
function DieIcon({ type, origin, size, }) {
    const dieTypes = {
        [AttackDieType.Yellow]: AttackDieYellow,
        [AttackDieType.Blue]: AttackDieBlue,
        [AttackDieType.Red]: AttackDieRed,
        [DefenseDieType.Brown]: DefenseDieBrown,
        [DefenseDieType.Grey]: DefenseDieGrey,
        [DefenseDieType.Black]: DefenseDieBlack,
    };
    const Die = dieTypes[type];
    if (!Die) {
        return null;
    }
    return React.createElement(Die, { origin: origin, size: size });
}
export default DieIcon;
