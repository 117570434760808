import React from 'react';
import Hex from '../../map/Hex.js';
import { TERRAIN_COLOR_PLAIN } from '../colors.js';
function PlainHex(props) {
    let style = (props && props.style) || {};
    return (React.createElement(Hex, { ...props, style: {
            ...style,
            fill: TERRAIN_COLOR_PLAIN,
        } }, props.children));
}
export const graphicalVariants = ['default'];
export default PlainHex;
