import React from 'react';
function UnitIconKnight() {
    return (React.createElement("g", { transform: "translate(-61.1 -77.13)" },
        React.createElement("rect", { x: "61.232", y: "77.262", width: "56.735", height: "44.735", fill: "none" }),
        React.createElement("g", { transform: "translate(60.214 -213.52)" },
            React.createElement("g", { stroke: "#000", strokeLinecap: "round" },
                React.createElement("g", { strokeLinejoin: "round" },
                    React.createElement("rect", { transform: "matrix(.71527 -.69885 .69646 .71759 0 0)", x: "-194.28", y: "219", width: ".49063", height: "37.796", rx: ".14568", ry: ".14557", color: "#000000", fill: "#520" }),
                    React.createElement("path", { d: "m15.396 293.36-1.0391 1.0317-2.1958-3.1972z", color: "#000000", fill: "#b3b3b3" }),
                    React.createElement("rect", { transform: "matrix(.71527 -.69885 .69646 .71759 0 0)", x: "-194.28", y: "220.6", width: ".50253", height: "1.4601", rx: ".14568", ry: ".14557", color: "#000000", fill: "#b3b3b3", strokeWidth: "1.0667" })),
                React.createElement("path", { d: "m16.468 295.92c2.8085-1.6978 3.9905-0.98332 7.1132-1.8646l-1.2947 0.69446 1.7601-0.1221-1.2096 0.75938 1.6507-0.20062c-2.9993 1.3754-4.3296 0.28159-6.9606 1.8247z", color: "#000000", fill: "#006680" })),
            React.createElement("g", { fillRule: "evenodd", stroke: "#000" },
                React.createElement("path", { d: "m14.37 305.31c-0.81448-0.67946-1.6565-1.4377-1.215-2.3397 0.61535 0.41907 1.9188 0.71725 2.286 1.2256 0.17269 0.42525-0.71795 0.96665-1.071 1.1142z", fill: "#2b1100" }),
                React.createElement("path", { d: "m14.644 304.23c0.05306-0.75259 0.19468-1.3511 0.84919-1.8904 0.59867-0.56155 0.71531 0.13228 1.4814-0.027 0.76522-0.0431 1.5304 0.94096 2.1045 1.3262 0.83909 0.24076 0.80032 0.75598 1.618 1.1673 0.86725 0.4706 1.2575 0.7956 1.687 1.6921 0.5357 0.77456 1.3297 1.0902 1.962 1.7525 0.32028 0.28752 1.4368 0.0603 1.291 0.63289-0.35402 0.31476-0.1809 0.68765-0.04013 1.2081 0.11279 0.38763 0.04409 0.76967-0.48568 0.53072-0.71213-0.30934-1.2958-0.85553-2.0294-1.125-0.92354-0.40086-1.9477-0.72051-2.5943-1.5495-0.56816-0.66809-1.1027-1.4086-1.9097-1.8048-0.80394-0.52796-1.4101-1.3776-2.3407-1.694-0.52686-0.0948-1.0577-0.19517-1.5932-0.21911z", fill: "#502d16" }),
                React.createElement("path", { d: "m43.591 310.43c0.91646 0.14181 0.71295-0.47867 2.2323-0.416 3.441 0.0934 2.3908 6.0589 1.8726 7.1393-0.66467 2.1087 1.5691 6.0872-0.01519 6.6152-1.2004 0.0582-1.8983-0.45671-2.3443-1.5082-0.27875-1.0049 0.19948-2.0073 0.21436-3.0165-0.06618-1.3542-0.52618-2.8808-0.17184-3.7927 1.8682-2.8084-1.9512-2.786-1.8226-3.6617 0.04687-0.2048 0.12678-1.3605 0.04669-1.1821l-0.01846-0.0571z", fill: "#502d16" })),
            React.createElement("g", { stroke: "#000" },
                React.createElement("path", { d: "m26.145 320.45-2.9375 6.1581 0.786 5.2323", fill: "none" }),
                React.createElement("path", { d: "m40.382 321.46 1.3007 6.1907-1.9874 4.7864", fill: "none" }),
                React.createElement("path", { d: "m16.367 303.82c4.3895 4.0285 6.6219 5.9445 9.4962 6.6956 5.0263 1.3135 11.405-0.20936 17.583-0.65249 1.6025 12.923 1.5604 12.852-16.53 11.286-2.4227-0.10662-5.2617-3.5968-9.4014-8.4429-1.2813 1.8604-2.805 3.4076-4.2719 3.4326-4.8041-1.1368-2.8667-11.664 3.1245-12.319z", color: "#000000", fill: "#784421", strokeLinecap: "round", strokeLinejoin: "round" }),
                React.createElement("path", { d: "m17.272 305.17c0.77236-0.73766 1.5708-1.5608 1.1522-2.5401-0.58354 0.45496-1.8196 0.77868-2.1678 1.3306-0.16376 0.46168 0.68083 1.0494 1.0156 1.2096z", fill: "#2b1100", fillRule: "evenodd" })),
            React.createElement("ellipse", { cx: "14.577", cy: "307", rx: ".30686", ry: ".69849", color: "#000000" }),
            React.createElement("g", { fill: "none", stroke: "#000" },
                React.createElement("path", { d: "m12.33 311.92c-1.2151 1.2202 0.17359 1.6851 0.17359 1.6851" }),
                React.createElement("path", { d: "m10.552 310.08s5.0918 0.11621 5.7861 4.4161", strokeLinecap: "round", strokeWidth: "1.0667px" }),
                React.createElement("path", { d: "m11.043 308.68s5.2075 0.34864 5.9019 4.6485", strokeLinecap: "round" }),
                React.createElement("path", { d: "m15.701 310.72s-0.86792 0.23243-0.92579 0.75539" })),
            React.createElement("path", { d: "m27.592 310.23c0.36956-1.1015 0.27295-1.1504 0.5512-1.212 0.81842-0.18112 0.90765-0.19043 0.80855 1.212 1.9496 0.45464 3.5439 0.31465 5.0339 0 0.36023-1.2729 0.84477-1.3071 0.84477-1.3071 0.69042 3e-3 0.4563 0.0737 0.57282 1.3071 0.77166 10.474-10.461 6.3234-7.8113 0z", color: "#000000", fill: "#a40", stroke: "#000", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("ellipse", { cx: "13.256", cy: "307", rx: ".30686", ry: ".69849", color: "#000000" }),
            React.createElement("path", { d: "m16.899 313.33c5.9996-0.20432 9.2589-2.6961 9.8877-3.904 2.6011-4.9969-4.1782-2.8559-4.303-2.7558", fill: "none", stroke: "#000" }),
            React.createElement("g", { transform: "matrix(-.12817 0 0 .1286 43.861 273.89)", fill: "none", stroke: "#000", strokeWidth: "8.3082px" },
                React.createElement("path", { d: "m123.82 211.55c-3.902 20.625-15.046 22.082 18.214 39.286" }),
                React.createElement("path", { d: "m144.61 239.89c-21.218 4.2888-4.238 20.429-4.2379 20.43" })),
            React.createElement("path", { d: "m31.381 310.74-0.64087 8.405h-2.4261", fill: "none", stroke: "#000", strokeWidth: "1.0667px" }),
            React.createElement("g", { stroke: "#000", strokeLinecap: "round" },
                React.createElement("path", { d: "m31.421 316.77-1.2961-0.13779-0.28692 1.8806c-1.287-0.0498-2.434-0.34484-2.5814 1.2585l4.3132-0.0325z", color: "#000000", fill: "#520" }),
                React.createElement("rect", { transform: "matrix(.97175 -.23599 .23451 .97211 0 0)", x: "-44.575", y: "299.66", width: "6.3493", height: "9.9465", rx: ".14547", ry: ".14578", color: "#000000", fill: "#999", strokeLinejoin: "round" }),
                React.createElement("rect", { transform: "matrix(.97175 -.23599 .23451 .97211 0 0)", x: "-44.606", y: "307.03", width: "6.4096", height: "2.6137", rx: ".14547", ry: ".14578", color: "#000000", fill: "#1a1a1a", strokeLinejoin: "round", strokeWidth: ".53271" }),
                React.createElement("g", null,
                    React.createElement("path", { d: "m36.856 316.75-0.19388 0.96048-0.86073-0.4632-3.9863-8.4024 1.0546-0.49728z", color: "#000000", fill: "#b3b3b3", strokeWidth: "1.0667" }),
                    React.createElement("rect", { transform: "matrix(-.90449 .42649 -.42417 -.90558 0 0)", x: "101.09", y: "-292.39", width: ".98842", height: "2.5312", color: "#000000", fill: "#520", strokeWidth: ".92441" }),
                    React.createElement("rect", { transform: "matrix(-.90449 .42649 -.42417 -.90558 0 0)", x: "99.839", y: "-293.02", width: "3.4979", height: ".84389", color: "#000000", fill: "#666", strokeWidth: "1.0667" }),
                    React.createElement("path", { d: "m37.054 316.83-0.31673 0.99023-0.96249-0.38741-3.8702-8.1258 1.2792-0.60284z", color: "#000000", fill: "#a05a2c" }))),
            React.createElement("g", { transform: "matrix(.12817 0 0 .1286 18.523 273.89)", fill: "none", stroke: "#000", strokeWidth: "8.3082px" },
                React.createElement("path", { d: "m63.76 253.47c18.768 10.788-2.4504 20.72-2.4506 20.72" }),
                React.createElement("path", { d: "m112.2 208.01c19.669 8.1251-11.643 57.76-48.74 56.036" })),
            React.createElement("g", null,
                React.createElement("path", { d: "m41.382 307.93-1.2184 0.51725-0.61136 0.82138-1.4399 0.62284-0.73153 0.96575-0.91076 0.23619-0.35343 0.65222-0.7702 0.0712-0.43219 0.84485c-0.38656-0.48795-0.42117-1.2088-0.76427-1.6588-4.0542-5.317-3.9079-7.4471-4.5835-7.6751-2.1157-0.71395-2.4125-2.271 3.2924-3.3248 0.72724-0.13433 1.9636 1.7968 2.8062 2.4467 1.1657 0.89917 2.7232 1.9062 3.7317 3.6932 0.36306 0.64336 1.6748 1.0221 1.9854 1.787z", color: "#000000", fill: "#2c89a0", stroke: "#000", strokeLinecap: "round" }),
                React.createElement("ellipse", { cx: "28.86", cy: "297.66", rx: "4.2835", ry: "4.2978", color: "#000000", fill: "#e9c6af", stroke: "#000", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "1.4258" }),
                React.createElement("ellipse", { cx: "27.617", cy: "296.84", rx: ".35606", ry: ".57803", color: "#000000" }),
                React.createElement("ellipse", { cx: "25.869", cy: "296.84", rx: ".35606", ry: ".57803", color: "#000000" })),
            React.createElement("g", { stroke: "#000" },
                React.createElement("path", { d: "m27.005 295.79 1.2456-0.043", fill: "none", strokeWidth: "1.0667px" }),
                React.createElement("path", { d: "m25.16 295.74 1.2458-0.0356", fill: "none", strokeWidth: "1.0667px" }),
                React.createElement("g", null,
                    React.createElement("path", { d: "m25.103 332.8-2.4343 9e-3c0.67669-1.9914 2.3785-1.9561 2.4343-9e-3z", color: "#000000", strokeLinecap: "round" }),
                    React.createElement("path", { d: "m26.878 320.77-1.3296 7.083 3.0773 4.2919", fill: "none" }),
                    React.createElement("path", { d: "m30.049 331.83-1.2259 2.1101c-1.3681-1.5944-0.47537-3.0486 1.2259-2.1101z", color: "#000000", strokeLinecap: "round" }),
                    React.createElement("path", { d: "m41.206 320.64 3.1738 6.4744-2.1209 6.7991", fill: "none" }))),
            React.createElement("g", null,
                React.createElement("g", { stroke: "#000", strokeLinecap: "round" },
                    React.createElement("g", null,
                        React.createElement("path", { d: "m40.245 333.87-2.2275-0.9852c1.4254-1.5431 2.9658-0.81642 2.2275 0.9852z", color: "#000000" }),
                        React.createElement("path", { d: "m43.255 335.08-2.4343 9e-3c0.67669-1.9914 2.3785-1.956 2.4343-9e-3z", color: "#000000" }),
                        React.createElement("path", { d: "m41.382 307.93-1.2184 0.51725-0.61136 0.82138-1.4399 0.62284-0.73153 0.96575-0.91076 0.23619-0.35343 0.65222-0.7702 0.0712-0.43219 0.84485c-0.187-0.23605-0.29165-0.52661-0.38869-0.81464 0.19714-0.14801 0.29201-0.54828 0.47727-0.68674 0.15844-0.11842 0.4088 0.0237 0.5591-0.0882 0.17336-0.12907 0.16522-0.49508 0.32846-0.61608 0.28515-0.21139 0.73169-0.16984 0.98917-0.35935 0.30184-0.22215 0.44187-0.62335 0.71174-0.82059 0.47433-0.34665 1.0486-0.46623 1.4521-0.75966 0.27406-0.19928 0.48784-0.67914 0.74306-0.86559 0.41748-0.074 0.68026-0.17378 0.99172-0.4052 0.26759 0.19971 0.49608 0.41873 0.60389 0.6843z", color: "#000000", fill: "#37abc8", strokeWidth: "1.0667" })),
                    React.createElement("path", { d: "m24.934 305.64 6.1818-3.4504c0.41517-0.23173 0.94935-0.0577 1.1977 0.39027 2.7769 5.0084 6.2199 9.4319 3.0997 14.66-5.3231-0.0851-7.922-5.2183-10.78-10.373-0.24836-0.44794-0.11407-0.99512 0.3011-1.2268z", color: "#000000", fill: "#502d16", strokeLinejoin: "round" })),
                React.createElement("path", { d: "m25.567 306.1 5.6058-3.129c0.37649-0.21014 0.86101-0.0521 1.0864 0.35437 2.5198 4.5446 5.6435 8.5586 2.8147 13.301-4.8279-0.0785-7.1862-4.7362-9.7797-9.4138-0.22536-0.40645-0.1037-0.90285 0.27279-1.113z", color: "#000000", fill: "#784421" }),
                React.createElement("g", { fill: "#520" },
                    React.createElement("path", { d: "m31.034 308.04-0.44478 0.8559 5.5259 2.8156c-0.05883-0.41755-0.1495-0.8329-0.26776-1.2466z", color: "#000000" }),
                    React.createElement("path", { d: "m30.096 308.56 0.95934 0.0722-0.52958 6.1956c-0.32257-0.27034-0.62649-0.56666-0.91444-0.88562z", color: "#000000" }),
                    React.createElement("path", { d: "m25.533 306.12c-0.27355 0.17225-0.39892 0.50158-0.33625 0.82576l4.711 1.7118-0.08428-0.896z", color: "#000000" }),
                    React.createElement("path", { d: "m31.206 302.95c0.29012-0.14237 0.63512-0.0756 0.8766 0.1488l-1.0347 4.9188-0.71432-0.54433z", color: "#000000" })),
                React.createElement("ellipse", { transform: "matrix(.87319 -.48738 .48491 .87457 0 0)", cx: "-122.79", cy: "284.1", rx: ".95563", ry: ".95731", color: "#000000", fill: "#808080" }),
                React.createElement("path", { d: "m28.86 293.37c-2.3657 9e-5 -4.2834 1.9243-4.2833 4.2978-5.1e-5 2.3736 1.9176 4.2978 4.2833 4.2979 2.3658 4e-5 4.2836-1.9242 4.2836-4.2979 5.1e-5 -2.3736-1.9178-4.2979-4.2836-4.2978zm-3.169 2.2432c1.3271-0.47916 2.0203-0.17545 2.8173 0 0.07871 0.0173 0.11742 0.0702 0.14545 0.14594 0.55312 1.4944 0.63482 3.1928 0 4.3059-0.03999 0.0701-0.06638 0.13006-0.14545 0.14568-0.9628 0.19021-1.8971 0.15181-2.8173 0-0.07952-0.0131-0.11965-0.0692-0.14545-0.14568-0.5577-1.6535-0.68194-3.544 0-4.3059 0.0538-0.0601 0.06962-0.11856 0.14545-0.14594z", color: "#000000", fill: "#999", stroke: "#000", strokeLinecap: "round", strokeLinejoin: "round" }),
                React.createElement("g", { fill: "#333" },
                    React.createElement("path", { d: "m33.57 299.18-4.3961-0.72524c0.0099-2.849-0.4361-2.8534-1.893-2.8851-0.06104-0.0203-0.10103 1.7524-0.25426 2.4598l-0.48132 0.0328c0.02863-0.23743-0.13303-2.4683-0.1827-2.4844-1.3719-0.44243-1.192 1.6926-1.3908 2.5801l-0.71572-0.18894c0.35864-4.1981 3.2121-4.8777 4.4874-5.3056 2.958 0.52738 4.4706 2.2336 4.9894 4.3361-0.0013 0.76215-0.11011 1.433-0.16287 2.1805z", color: "#000000", stroke: "#000", strokeLinecap: "round" }),
                    React.createElement("ellipse", { cx: "29.912", cy: "299.45", rx: ".12016", ry: ".12056", color: "#000000" }),
                    React.createElement("ellipse", { cx: "29.624", cy: "299.71", rx: ".12016", ry: ".12056", color: "#000000" }),
                    React.createElement("ellipse", { cx: "29.236", cy: "300.6", rx: ".12016", ry: ".12056", color: "#000000" }),
                    React.createElement("ellipse", { cx: "27.951", cy: "300.85", rx: ".12016", ry: ".12056", color: "#000000" }),
                    React.createElement("ellipse", { cx: "27.241", cy: "300.97", rx: ".12016", ry: ".12056", color: "#000000" }),
                    React.createElement("ellipse", { cx: "29.68", cy: "300.47", rx: ".12016", ry: ".12056", color: "#000000" }),
                    React.createElement("ellipse", { cx: "27.964", cy: "301.21", rx: ".12016", ry: ".12056", color: "#000000" }),
                    React.createElement("ellipse", { cx: "28.575", cy: "300.92", rx: ".12016", ry: ".12056", color: "#000000" }),
                    React.createElement("ellipse", { cx: "28.853", cy: "301.29", rx: ".12016", ry: ".12056", color: "#000000" }),
                    React.createElement("ellipse", { cx: "29.807", cy: "300.11", rx: ".12016", ry: ".12056", color: "#000000" }),
                    React.createElement("ellipse", { cx: "29.334", cy: "301.03", rx: ".12016", ry: ".12056", color: "#000000" }),
                    React.createElement("ellipse", { cx: "30.38", cy: "300.7", rx: ".12016", ry: ".12056", color: "#000000" }),
                    React.createElement("ellipse", { cx: "30.557", cy: "299.99", rx: ".12016", ry: ".12056", color: "#000000" }),
                    React.createElement("ellipse", { cx: "30.333", cy: "299.66", rx: ".12016", ry: ".12056", color: "#000000" }),
                    React.createElement("ellipse", { cx: "31.194", cy: "299.62", rx: ".12016", ry: ".12056", color: "#000000" }),
                    React.createElement("ellipse", { cx: "31.26", cy: "300.19", rx: ".12016", ry: ".12056", color: "#000000" }),
                    React.createElement("ellipse", { cx: "31.832", cy: "299.69", rx: ".12016", ry: ".12056", color: "#000000" }),
                    React.createElement("ellipse", { cx: "31.558", cy: "299.97", rx: ".12016", ry: ".12056", color: "#000000" }),
                    React.createElement("ellipse", { cx: "30.81", cy: "300.54", rx: ".12016", ry: ".12056", color: "#000000" }),
                    React.createElement("ellipse", { cx: "30.013", cy: "300.78", rx: ".12016", ry: ".12056", color: "#000000" }),
                    React.createElement("ellipse", { cx: "29.65", cy: "300.89", rx: ".12016", ry: ".12056", color: "#000000" })),
                React.createElement("ellipse", { transform: "matrix(.99455 -.10422 .10354 .99463 0 0)", cx: "-4.1544", cy: "300.71", rx: "1.0094", ry: ".48224", color: "#000000" })))));
}
export default UnitIconKnight;
