import React from 'react';
import { hexCorner } from '@wareena/game-algorithms';
export function Hex({ center, size, id, dimensions, isFlatTop = true, style = {}, graphicalVariant = 'default', wrapperStyle = {}, children, ...otherProps }) {
    const points = [
        hexCorner(center, size, 0, isFlatTop),
        hexCorner(center, size, 1, isFlatTop),
        hexCorner(center, size, 2, isFlatTop),
        hexCorner(center, size, 3, isFlatTop),
        hexCorner(center, size, 4, isFlatTop),
        hexCorner(center, size, 5, isFlatTop),
    ];
    const hexStyle = {
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1, //Math.max(1, Math.round(size / 25)),
        ...style,
    };
    let idText = null;
    if (id !== undefined) {
        const fontSize = size / 5;
        const textPosition = {
            x: center.x,
            y: center.y + fontSize - (isFlatTop ? dimensions.height : dimensions.width) / 2,
        };
        const textProps = {};
        if (!isFlatTop) {
            textProps.transform = `rotate(270 ${center.x} ${center.y})`;
        }
        idText = (React.createElement("text", { x: textPosition.x, y: textPosition.y, fontFamily: "Verdana", fontSize: fontSize, textAnchor: "middle", fill: "rgba(0, 0, 0, 0.5)", ...textProps }, id));
    }
    return (React.createElement("g", { ...otherProps, style: wrapperStyle },
        React.createElement("polygon", { points: points.map((point) => `${point.x} ${point.y}`).join(', '), style: hexStyle }),
        children,
        idText));
}
export default Hex;
