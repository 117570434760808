import React from 'react';
export default function UnitIconLongbow() {
    return (React.createElement("g", { transform: "matrix(-1 0 0 1 61.27 0)" },
        React.createElement("g", { stroke: "#000", transform: "matrix(-1 0 0 1 -14.513 -291.163)" },
            React.createElement("path", { fill: "#782121", strokeLinecap: "round", strokeLinejoin: "round", d: "M-49.523 306.65c8.907-.384 4.966 11.387 5.765 20.14 0 0-1.796-.706-2.744-.777-1.418-.106-2.81.537-4.232.541-1.247.003-2.467-.49-3.714-.454-.937.027-2.768.5-2.768.5 2.646-7.843-3.183-21.136 7.693-19.95z", color: "#000", colorRendering: "auto", imageRendering: "auto", shapeRendering: "auto", style: { isolation: 'auto', mixBlendMode: 'normal' } }),
            React.createElement("path", { fill: "#806600", strokeLinecap: "round", strokeLinejoin: "round", d: "M-56.847 325.3c-.11.588-.193.765-.376 1.308 0 0 1.832-.473 2.769-.5 1.246-.035 2.466.457 3.713.454 1.422-.004 2.815-.647 4.233-.541.947.07 2.744.777 2.744.777-.057-.616-.088-.691-.103-1.332 0 0-1.679-.521-2.547-.558-1.437-.06-2.84.584-4.277.57-1.26-.012-2.476-.575-3.736-.57-.817.004-2.42.392-2.42.392z", color: "#000", colorRendering: "auto", imageRendering: "auto", shapeRendering: "auto", style: { isolation: 'auto', mixBlendMode: 'normal' } }),
            React.createElement("path", { fill: "none", d: "M-46.948 322.38l.864 12.417h4.522" }),
            React.createElement("path", { fill: "none", d: "M-51.941 322.38l-.95 12.14h3.524" })),
        React.createElement("g", { stroke: "#000", strokeLinecap: "round", strokeLinejoin: "round", color: "#000", colorRendering: "auto", imageRendering: "auto", shapeRendering: "auto", transform: "scale(-1 1)" },
            React.createElement("rect", { width: "9.222", height: "14.538", x: "-39.49", y: "16.897", fill: "#c87137", rx: "0.211", ry: "0.213", style: { isolation: 'auto', mixBlendMode: 'normal' } }),
            React.createElement("rect", { width: "9.309", height: "3.82", x: "-39.533", y: "27.657", fill: "#1a1a1a", rx: "0.211", ry: "0.213", style: { isolation: 'auto', mixBlendMode: 'normal' } }),
            React.createElement("ellipse", { cx: "-35.02", cy: "12.777", fill: "#e9c6af", rx: "6.222", ry: "6.28", style: { isolation: 'auto', mixBlendMode: 'normal' } })),
        React.createElement("path", { fill: "none", stroke: "#000", d: "M39.628 17.187c3.662 1.527 8.204 3.412-8.013 4.833" }),
        React.createElement("path", { fill: "none", stroke: "#000", d: "M30.869 23.907c3.395-2.193-.174-3.8-.174-3.8" }),
        React.createElement("g", { color: "#000", colorRendering: "auto", imageRendering: "auto", shapeRendering: "auto", transform: "matrix(-1 0 0 1 -14.513 -291.163)" },
            React.createElement("ellipse", { cx: "-45.929", cy: "301.83", rx: "0.517", ry: "0.845", style: { isolation: 'auto', mixBlendMode: 'normal' } }),
            React.createElement("ellipse", { cx: "-48.169", cy: "302.36", rx: "0.517", ry: "0.845", style: { isolation: 'auto', mixBlendMode: 'normal' } }),
            React.createElement("path", { fill: "#520", stroke: "#000", strokeLinecap: "round", d: "M-46.798 331.24l1.883-.201.417 2.748c1.87-.073 3.535-.504 3.75 1.839l-6.266-.048z", style: { isolation: 'auto', mixBlendMode: 'normal' } }),
            React.createElement("path", { fill: "#520", stroke: "#000", strokeLinecap: "round", d: "M-53.778 331.2l1.883-.201.417 2.748c1.87-.073 3.535-.504 3.75 1.839l-6.266-.048z", style: { isolation: 'auto', mixBlendMode: 'normal' } })),
        React.createElement("path", { fill: "none", stroke: "#000", d: "M20.924 20.077c3.867 1.147.458 3.894.458 3.894" }),
        React.createElement("path", { fill: "none", stroke: "#000", d: "M30.362 17.127c-2.44 4.252-7.197 4.884-8.948 5.098" }),
        React.createElement("path", { d: "M34.231 14.307l-3.275-.823c-.194 1.36 2.56 2.937 3.275.823z", color: "#000", colorRendering: "auto", imageRendering: "auto", shapeRendering: "auto", style: { isolation: 'auto', mixBlendMode: 'normal' } }),
        React.createElement("g", { stroke: "#000", transform: "matrix(-1 0 0 1 -14.513 -291.163)" },
            React.createElement("path", { fill: "#a40", fillRule: "evenodd", d: "M-48.241 297.42c.335.644.912 1.093 1.12 1.876-.33.624-1.095.103-1.627.207-.765.05-1.47.563-1.824 1.235-.313.767.046 1.645-.347 2.393-.304 1.241-.49 2.5-.544 3.688-.018.57-.936-.094-1.422.198-1.524.957-2.653-.264-3.29-.879-.256-1.245.582-2.423.324-3.67-.033-.774.474-2.623 1.261-2.881.878-.604 1.146-.89 1.875-1.643.954-.728 1.977-.411 3.082-.684.509-.094.873.162 1.392.16z" }),
            React.createElement("path", { fill: "#a40", fillRule: "evenodd", d: "M-46.365 297.4c.322.213.793.38 1.095.622.446.153.959.912.985 1.417.012.235-.047.479-.153.804-.471-.78-1.468-.945-2.068-.625-.251.18-.8.113-.758-.284-.06-.289-.517-.66-.443-.921-.025-.379-.54-.724-.627-1.07.203-.304 1.825-.083 1.969.057z" }),
            React.createElement("path", { fill: "none", d: "M-44.393 292.08l-1.723 20.873 4.108 18.665" })),
        React.createElement("g", { color: "#000", colorRendering: "auto", imageRendering: "auto", shapeRendering: "auto", transform: "matrix(-1 0 0 1 -14.513 -291.163)" },
            React.createElement("path", { fill: "#803300", stroke: "#000", strokeLinecap: "round", strokeLinejoin: "round", d: "M-42.771 332.17c3.13-3.6 6.882-15.887 6.869-19.7-.13-4.358-6.657-17.289-8.825-20.771 3.064 1.862 10.205 15.686 10.353 20.65.159 5.586-4.891 18.24-8.397 19.822z", style: { isolation: 'auto', mixBlendMode: 'normal' } }),
            React.createElement("rect", { width: "15.611", height: "0.522", x: "-67.432", y: "308.98", fill: "#803300", rx: "0", ry: "0", transform: "rotate(-4.003) skewX(-.073)", style: { isolation: 'auto', mixBlendMode: 'normal' } }),
            React.createElement("path", { fill: "#b3b3b3", stroke: "#000", strokeLinecap: "round", strokeLinejoin: "round", d: "M-30.437 312.92l-.104-1.52 2.15.462z", style: { isolation: 'auto', mixBlendMode: 'normal' } }),
            React.createElement("path", { fill: "#786721", d: "M-46.254 312.02l1.866-.13.369.882-1.808.126z", style: { isolation: 'auto', mixBlendMode: 'normal' } }),
            React.createElement("path", { fill: "#786721", d: "M-46.092 314.38l1.866-.13.244-.926-1.807.127z", style: { isolation: 'auto', mixBlendMode: 'normal' } }))));
}
