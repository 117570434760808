export var Side;
(function (Side) {
    Side["A"] = "A";
    Side["B"] = "B";
})(Side || (Side = {}));
export const SIDE_A = Side.A;
export const SIDE_B = Side.B;
export var SideId;
(function (SideId) {
    SideId[SideId["A"] = 1] = "A";
    SideId[SideId["B"] = 2] = "B";
})(SideId || (SideId = {}));
export const sideIds = {
    [SIDE_A]: SideId.A,
    [SIDE_B]: SideId.B,
};
export const getSideName = (sideId) => {
    return (Object.keys(sideIds).find((key) => {
        if (sideIds[key] === sideId) {
            return true;
        }
        return false;
    }) ?? null);
};
const sides = [SIDE_A, SIDE_B];
export default sides;
