import React from 'react';
export default function Maceman() {
    return (React.createElement("g", null,
        React.createElement("g", { strokeDasharray: "none", strokeMiterlimit: "4" },
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M29.711 31.006l.859 12.218h4.49" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M25.022 31.006l-.924 12.086h-3.5" }),
            React.createElement("rect", { width: "9.159", height: "14.305", x: "22.705", y: "16.924", fill: "#999", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", rx: "0.21", ry: "0.21", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M31.89 17.138c3.638 1.502 3.228 3.422 5.416 13.472" }),
            React.createElement("path", { d: "M31.07 16.357c2.34.324 2.468.368 2.502 2.72l-2.945-.502z", fill: "#999", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M23.294 16.61c-3.637 1.502-9.298 12.805-5.415 13.472" }),
            React.createElement("path", { fill: "#999", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", d: "M23.368 16.357c-2.339.324-2.468.368-2.501 2.72l2.945-.502z", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("g", { strokeLinecap: "round", strokeOpacity: "1", strokeWidth: "1" },
                React.createElement("path", { fill: "#666", fillOpacity: "1", fillRule: "nonzero", stroke: "#4d4d4d", strokeDashoffset: "0", strokeLinejoin: "round", d: "M17.686 13.923l-.657.069.466 4.467.617-.057c-.13-.775-.213-1.979.408-3.005-.405-.529-.672-.744-.834-1.474z", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
                React.createElement("path", { d: "M15.322 14.17l.656-.068.467 4.466-.615.073c-.032-.786-.2-1.98-1.02-2.857.287-.6.503-.866.512-1.614z", fill: "#666", fillOpacity: "1", fillRule: "nonzero", stroke: "#4d4d4d", strokeDashoffset: "0", strokeLinejoin: "round", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
                React.createElement("path", { d: "M16.693 13.66l-.429.045.125-.598z", fill: "#666", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinejoin: "round", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
                React.createElement("rect", { width: "1.057", height: "19.02", x: "14.427", y: "15.289", fill: "#666", fillOpacity: "1", fillRule: "nonzero", stroke: "#4d4d4d", strokeDashoffset: "0", strokeLinejoin: "round", clipRule: "nonzero", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", overflow: "visible", rx: "0.21", ry: "0.21", color: "#000", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", shapeRendering: "auto", textRendering: "auto", transform: "rotate(-5.963)", visibility: "visible" }),
                React.createElement("path", { d: "M17.226 13.971l-.425.045.42 4.471.431-.037c-.13-.775-.411-1.97.21-2.996-.406-.529-.473-.753-.636-1.483z", fill: "#666", fillOpacity: "1", fillRule: "nonzero", stroke: "gray", strokeDashoffset: "0", strokeLinejoin: "round", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
                React.createElement("path", { fill: "#666", fillOpacity: "1", fillRule: "nonzero", stroke: "gray", strokeDashoffset: "0", strokeLinejoin: "round", d: "M15.816 14.119l.425-.045.513 4.462-.43.053c-.032-.785-.004-2.013-.824-2.889.288-.6.308-.834.316-1.581z", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
                React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#4d4d4d", strokeLinejoin: "miter", d: "M16.507 14.034l.492 4.491" })),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M18.139 31.256c-3.365-2.169.647-3.804.647-3.804" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M38.814 30.544c-2.003-3.466-3.83.462-3.83.462" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M24.857 14.958c.133.609 4.555.768 4.524.066" }),
            React.createElement("path", { d: "M29.861 39.732l1.87-.198.414 2.705c1.857-.072 3.511-.496 3.724 1.81L29.646 44z", fill: "#666", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "#666", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M25.224 39.732l-1.87-.198-.414 2.705c-1.857-.072-3.511-.496-3.724 1.81L25.439 44z", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("g", { fillOpacity: "1", fillRule: "nonzero", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", imageRendering: "auto", shapeRendering: "auto", textRendering: "auto", transform: "translate(.25 -.466)", visibility: "visible" },
                React.createElement("circle", { cx: "26.894", cy: "13.33", r: "6.18", fill: "#e9c6af", stroke: "#000", strokeWidth: "1", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                React.createElement("path", { fill: "gray", stroke: "#000", strokeWidth: "0.5", d: "M26.837 7.057a6.18 6.18 0 100 12.36 6.18 6.18 0 000-12.36zm-2.99 2.009c1.84.407 4.017.264 6.075.016.566 2.765.895 5.684.033 8.404-2.07.374-4.159.364-6.274-.23-.793-1.704-.577-4.73.166-8.19z", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                React.createElement("g", { fill: "#333", stroke: "none", strokeWidth: "1" },
                    React.createElement("circle", { cx: "22.022", cy: "11.278", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "22.377", cy: "12.087", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "21.593", cy: "11.972", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "22.967", cy: "10.669", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "22.336", cy: "10.395", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "22.955", cy: "9.594", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "22.281", cy: "9.711", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "21.725", cy: "10.632", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "21.106", cy: "13.297", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "21.634", cy: "12.921", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "22.55", cy: "12.739", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "22.394", cy: "13.474", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "21.411", cy: "14.201", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "22.129", cy: "14.234", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "21.916", cy: "15.422", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "22.638", cy: "15.93", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "22.404", cy: "16.724", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "23.245", cy: "17.634", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "24.296", cy: "18.078", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "25.883", cy: "18.195", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "25.556", cy: "18.802", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "25.183", cy: "18.078", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "26.841", cy: "18.195", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "26.234", cy: "18.872", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "28.172", cy: "18.522", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "29.094", cy: "18.101", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "30.833", cy: "17.004", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "31.3", cy: "15.206", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "31.978", cy: "14.155", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "31.534", cy: "12.451", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "31.394", cy: "11.75", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "31.067", cy: "10.746", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "30.553", cy: "9.836", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "29.152", cy: "8.131", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "27.144", cy: "8.108", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "26.047", cy: "8.131", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "24.599", cy: "8.318", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "26.234", cy: "8.505", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "27.868", cy: "8.551", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "31.114", cy: "13.502", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "32.234", cy: "12.778", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "32.281", cy: "11.4", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "31.16", cy: "9.742", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "30.903", cy: "12.171", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "30.973", cy: "14.482", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "30.857", cy: "15.533", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "22.708", cy: "14.552", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "22.871", cy: "11.692", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "31.312", cy: "16.175", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "30.215", cy: "17.786", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "27.483", cy: "18.288", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "30.286", cy: "8.785", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "23.599", cy: "8.604", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "25.332", cy: "7.729", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                    React.createElement("circle", { cx: "25.448", cy: "8.769", r: "0.173", display: "inline", enableBackground: "accumulate", overflow: "visible" }))),
            React.createElement("g", { fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", imageRendering: "auto", shapeRendering: "auto", textRendering: "auto", transform: "translate(.25 -.466)", visibility: "visible" },
                React.createElement("ellipse", { cx: "28.492", cy: "11.639", fill: "#000", display: "inline", enableBackground: "accumulate", overflow: "visible", rx: "0.514", ry: "0.831" }),
                React.createElement("ellipse", { cx: "25.322", cy: "11.732", fill: "#000", display: "inline", enableBackground: "accumulate", overflow: "visible", rx: "0.514", ry: "0.831" }),
                React.createElement("path", { d: "M24.963 14.89c.584-.09 1.116.018 1.53-.157.48-.204.668-.089.878.005.438.195.934.088 1.433.175.115.02.377.167.409.28l.128.456c.031.111-.246.207-.362.198-.584-.05-1.077.138-1.681-.08-.077-.028-.36-.204-.666-.06-.6.282-1.188.075-1.938.14-.116.01-.338-.17-.303-.28l.117-.375c.034-.11.34-.285.455-.303z", fill: "#a05a2c", display: "inline", enableBackground: "accumulate", overflow: "visible" })),
            React.createElement("g", { fill: "#333", strokeWidth: "5.408", transform: "matrix(.18492 0 0 .18492 -12.264 -34.988)" },
                React.createElement("path", { fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDasharray: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: "4", strokeOpacity: "1", strokeWidth: "5.408", d: "M251.02 246.387c.029 2.063-.77 24.04-.77 24.04l-9.79 4.462s-1.218-30.435-1.51-30.684c-7.023-6-10.583-7.36-22.507-7.615-.294-.006-.651 22.844-.945 22.84-1.057-.01-3.188.064-4.243.096-.25.008-.688-23.09-.936-23.08-12.128.485-12.855.532-19.481 6.379-.255.225-4.076 32.938-4.28 32.603l-9.518-5.463-1.524-23.578c4.106-23.112 30.374-37.704 37.752-37.752 7.298-.047 34.266 15.103 37.752 37.752z", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", opacity: "1", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" })),
            React.createElement("rect", { width: "9.159", height: "3.261", x: "22.705", y: "27.968", fill: "#999", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", rx: "0.21", ry: "0.21", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { d: "M31.17 20.875h10.207c.685 0 1.237.593 1.237 1.33 0 8.241 1.249 16.215-6.34 20.596-6.652-3.84-6.34-12.113-6.34-20.596 0-.737.552-1.33 1.237-1.33z", fill: "#483e37", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "#784421", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", d: "M31.65 21.89h9.255c.621 0 1.122.539 1.122 1.207 0 7.478 1.132 14.713-5.75 18.689-6.032-3.485-5.75-10.992-5.75-18.689 0-.668.501-1.207 1.123-1.207z", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "#2b1100", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", d: "M37.187 28.162l-1.16.764 5 7.414c.217-.566.393-1.152.533-1.755z", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { d: "M35.638 28.162l1.159.764-4.999 7.414a13.2 13.2 0 01-.534-1.755z", fill: "#2b1100", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "#2b1100", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", d: "M31.592 21.893c-.465.025-.854.35-1.001.802l4.743 5.455.52-1.185z", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { d: "M40.96 21.893c.464.025.853.35 1 .802l-4.743 5.455-.52-1.185z", fill: "#2b1100", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "36.379", cy: "28.159", r: "1.378", fill: "gray", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }))));
}
