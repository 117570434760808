import React from 'react';
export default function Axeman() {
    return (React.createElement("g", null,
        React.createElement("g", { strokeDasharray: "none", strokeMiterlimit: "4", transform: "translate(.049 -.64)" },
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M29.923 31.97l.852 12.12h4.455" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M25.271 31.97l-.917 11.99h-3.472" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M32.085 18.21c3.609 1.491 3.202 3.397 5.373 13.366" }),
            React.createElement("g", { fillOpacity: "1", fillRule: "nonzero", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", imageRendering: "auto", shapeRendering: "auto", textRendering: "auto", visibility: "visible" },
                React.createElement("path", { d: "M15.184 8.082c-.149-.016-.214.116-.296.241-.734 1.116-.558 2.217-.34 3.318.03.147.093.281.242.297.58-.086 3.391-.625 4.11-.812l.126-1.823c-.57-.116-2.792-.69-3.842-1.221z", fill: "#b3b3b3", stroke: "#4d4d4d", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                React.createElement("path", { d: "M20.574 9.788l-.856-.178c-.113-.024-.185.165-.197.28l-.076.75c-.012.114.05.363.164.357l.846-.045c.115-.006.217-.071.229-.186l.076-.75c.012-.115-.073-.205-.186-.228z", fill: "#b3b3b3", stroke: "#4d4d4d", display: "inline", enableBackground: "accumulate", overflow: "visible" }),
                React.createElement("rect", { width: "0.834", height: "15.751", x: "-20.526", y: "6.376", fill: "#2b1100", stroke: "#2b1100", overflow: "visible", rx: "0.208", ry: "0.208", display: "inline", enableBackground: "accumulate", transform: "scale(-1 1) rotate(-5.835)" })),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M22.836 18.276c-3.067 1.394.667 17.58-4.63 2.525" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M18.041 20.134c2.942 2.668-1.25 3.618-1.25 3.618" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M38.954 31.51c-1.988-3.437-3.8.46-3.8.46" }),
            React.createElement("path", { d: "M30.072 40.626l1.855-.196.41 2.682c1.842-.07 3.484-.492 3.695 1.796l-6.173-.047z", fill: "#520", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "#520", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeOpacity: "1", strokeWidth: "1", d: "M25.471 40.626l-1.855-.196-.41 2.682c-1.842-.07-3.484-.492-3.695 1.796l6.173-.047z", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("g", { fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "5.451", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", imageRendering: "auto", shapeRendering: "auto", textRendering: "auto", transform: "matrix(.18345 0 0 .18345 -47.67 -79.082)", visibility: "visible" },
                React.createElement("rect", { width: "51.963", height: "77.358", x: "382.863", y: "528.819", fill: "#666", display: "inline", enableBackground: "accumulate", opacity: "1", overflow: "visible", rx: "1.135", ry: "1.134" }),
                React.createElement("rect", { width: "51.953", height: "20.318", x: "382.884", y: "585.898", fill: "#1a1a1a", display: "inline", enableBackground: "accumulate", opacity: "1", overflow: "visible", rx: "1.135", ry: "1.134" })),
            React.createElement("circle", { cx: "23.451", cy: "18.426", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "23.926", cy: "19.539", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "25.709", cy: "18.589", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "25.269", cy: "20.813", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "23.694", cy: "21.948", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "23.763", cy: "23.94", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "25.663", cy: "22.874", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "25.431", cy: "25.191", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "24.111", cy: "25.978", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "24.412", cy: "27.415", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "25.686", cy: "26.812", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "24.806", cy: "24.033", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "24.759", cy: "21.693", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "23.787", cy: "20.35", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "26.288", cy: "19.33", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "27.748", cy: "23.315", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "28.466", cy: "24.589", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "27.169", cy: "26.998", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "26.589", cy: "26.117", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "26.543", cy: "23.755", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "29.091", cy: "22.735", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "28.998", cy: "21.021", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "26.219", cy: "21.438", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "27.006", cy: "20.465", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "27.377", cy: "18.357", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "29.253", cy: "18.612", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "28.628", cy: "20.002", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "29.045", cy: "19.261", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "30.087", cy: "19.006", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "30.828", cy: "19.4", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "30.574", cy: "20.604", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "30.249", cy: "22.457", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "30.967", cy: "25.283", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "29.323", cy: "27.206", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "28.026", cy: "27.183", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "30.226", cy: "27.692", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "29.578", cy: "25.561", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "30.574", cy: "24.241", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "29.276", cy: "23.778", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "27.47", cy: "24.774", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "28.396", cy: "25.909", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "26.242", cy: "27.692", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "23.425", cy: "26.988", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "23.37", cy: "25.26", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "30.875", cy: "26.65", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "31.037", cy: "22.921", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "24.157", cy: "22.573", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "25.339", cy: "19.516", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "28.35", cy: "21.624", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "26.983", cy: "22.203", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("circle", { cx: "27.493", cy: "19.4", r: "0.172", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("g", { fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "5.451", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", imageRendering: "auto", shapeRendering: "auto", textRendering: "auto", transform: "matrix(.18345 0 0 .18345 3.622 -25.661)", visibility: "visible" },
                React.createElement("circle", { cx: "174.63", cy: "282.247", r: "38.214", fill: "#2b1100", display: "inline", enableBackground: "accumulate", opacity: "1", overflow: "visible" }),
                React.createElement("circle", { cx: "174.63", cy: "282.247", r: "35.042", fill: "#784421", display: "inline", enableBackground: "accumulate", opacity: "1", overflow: "visible" }),
                React.createElement("circle", { cx: "174.63", cy: "282.247", r: "7.45", fill: "gray", display: "inline", enableBackground: "accumulate", opacity: "1", overflow: "visible" })),
            React.createElement("circle", { cx: "27.377", cy: "13.971", r: "6.131", fill: "#e9c6af", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: "1", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("g", { strokeOpacity: "1", strokeWidth: "1" },
                React.createElement("path", { fill: "#520", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", d: "M26.306 14.363c-.508-.31-1.768-.376-2.334-.359-1.354.277-1.112 2.417-1.136 2.449-.004 1.723 1.722 3.045 2.005 4.626.261.512.607 1.128 1.253 1.162.577-.024.763.83 1.35.393.849-.613 1.657-1.318 2.606-1.612.452-.308 1.161-1.314.935-1.898.096-1.071.627-1.391.933-2.382 1.834-3.375-2.733-2.34-3.203-2.434-.527-.281-1.148-.137-1.669.082-.242.083-.498.016-.74-.027z" }),
                React.createElement("ellipse", { cx: "27.28", cy: "16.459", fill: "#000", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", rx: "1.941", ry: "1.032", shapeRendering: "auto", textRendering: "auto", visibility: "visible" })),
            React.createElement("g", { strokeOpacity: "1" },
                React.createElement("ellipse", { cx: "29.381", cy: "12.133", fill: "#000", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", rx: "0.51", ry: "0.825", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
                React.createElement("ellipse", { cx: "25.523", cy: "12.151", fill: "#000", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", rx: "0.51", ry: "0.825", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
                React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "0.5", d: "M28.567 10.877l1.695-.557" }),
                React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "0.5", d: "M24.504 10.325l1.67.627" })),
            React.createElement("g", { fill: "#333", strokeWidth: "5.451", transform: "matrix(.18345 0 0 .18345 -11.814 -33.994)" },
                React.createElement("path", { d: "M251.02 246.387l-.012 10.656-8.527.168s-1.724-13.515-2.015-13.764c-7.024-6-12.099-6.602-24.023-6.857-.294-.006-.777 16.025-1.071 16.022-1.057-.01-2.935.064-3.99.096-.25.008-.815-16.272-1.063-16.263-12.128.486-18.916 1.796-25.542 7.643-.255.224-.858 13.683-1.25 13.663l-8.003-.413-.008-10.951c4.106-23.112 30.374-37.704 37.752-37.752 7.298-.047 34.266 15.103 37.752 37.752z", fill: "#333", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDasharray: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: "4", strokeOpacity: "1", strokeWidth: "5.451", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", opacity: "1", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" })))));
}
