import React from 'react';
import Hex from '../../map/Hex.js';
import { createVariants } from '../utils/withRotation.js';
import Stone1 from './variant/Stone1.js';
import { TERRAIN_COLOR_PLAIN } from '../colors.js';
const variants = {
    default: Stone1,
    ...createVariants('stone1', Stone1),
};
function BridgeHex({ style = {}, children, ...props }) {
    let variantName = props.graphicalVariant ?? 'default';
    if (variants[variantName] === undefined) {
        variantName = 'default';
    }
    let Variant = variants[variantName] ?? null;
    return (React.createElement(Hex, { ...props, style: {
            ...style,
            fill: TERRAIN_COLOR_PLAIN,
        } },
        React.createElement(Variant, { ...props }),
        children));
}
export const graphicalVariants = Object.keys(variants);
export default BridgeHex;
