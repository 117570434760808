import React from 'react';
export default function LightCavalry() {
    return (React.createElement("g", null,
        React.createElement("g", { strokeDasharray: "none", strokeMiterlimit: "4", strokeOpacity: "1", transform: "translate(.465 .366) scale(.9837)" },
            React.createElement("path", { fill: "#784421", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "1", d: "M12.54 13.342c-.866-.719-1.76-1.522-1.291-2.477.653.444 2.038.76 2.428 1.298.183.45-.763 1.023-1.138 1.18z" }),
            React.createElement("path", { fill: "#a05a2c", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "1", d: "M12.83 12.19c.057-.797.207-1.43.902-2.002.636-.594.76.14 1.573-.028.813-.046 1.626.996 2.235 1.404.891.255.85.8 1.718 1.236.921.498 1.336.842 1.792 1.791.569.82 1.412 1.154 2.084 1.856.34.304 1.525.063 1.37.67-.375.333-.192.728-.042 1.279.12.41.047.815-.516.562-.756-.328-1.376-.906-2.155-1.192-.98-.424-2.068-.762-2.755-1.64-.603-.707-1.171-1.491-2.028-1.91-.854-.56-1.498-1.46-2.486-1.794-.56-.1-1.123-.207-1.692-.232z" }),
            React.createElement("path", { fill: "#a05a2c", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "1", d: "M43.57 18.757c.974.15.758-.506 2.371-.44 3.654.099 2.539 6.415 1.989 7.559-.706 2.232 1.666 6.445-.017 7.003-1.274.062-2.015-.483-2.489-1.596-.296-1.064.212-2.126.228-3.194-.07-1.434-.56-3.05-.183-4.016 1.984-2.973-2.072-2.95-1.935-3.876.05-.217.134-1.44.05-1.252l-.02-.06z" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "1", d: "M25.044 29.37l-3.119 6.519.835 5.54" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "1", d: "M40.163 30.439l1.381 6.554-2.11 5.068" }),
            React.createElement("path", { d: "M14.66 11.758c4.662 4.265 7.033 6.294 10.085 7.089 5.337 1.39 12.112-.222 18.672-.69 1.702 13.68 1.657 13.606-17.554 11.948-2.573-.113-5.588-3.808-9.984-8.939-1.36 1.97-2.979 3.608-4.537 3.634-5.101-1.203-3.044-12.349 3.318-13.042z", fill: "#784421", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "#784421", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "1", d: "M15.622 13.188c.82-.78 1.668-1.652 1.223-2.69-.62.483-1.932.825-2.302 1.41-.174.488.723 1.11 1.079 1.28z" }),
            React.createElement("ellipse", { cx: "12.759", cy: "15.126", fill: "#000", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", rx: "0.326", ry: "0.74", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "1", d: "M10.373 20.34c-1.29 1.292.184 1.784.184 1.784" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "round", strokeLinejoin: "miter", strokeWidth: "1", d: "M8.485 18.388s5.407.123 6.145 4.676" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "round", strokeLinejoin: "miter", strokeWidth: "1", d: "M9.006 16.91s5.53.368 6.268 4.92" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "1", d: "M13.954 19.065s-.922.246-.984.8" }),
            React.createElement("path", { d: "M26.58 18.542c.393-1.166.29-1.218.586-1.283.87-.192.964-.202.859 1.283 2.07.481 3.763.333 5.345 0 .383-1.348.898-1.384.898-1.384.733.003.484.078.608 1.384.82 11.09-11.11 6.695-8.295 0z", fill: "#a40", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("ellipse", { cx: "11.357", cy: "15.126", fill: "#000", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", rx: "0.326", ry: "0.74", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "1", d: "M15.225 21.832c6.371-.216 9.832-2.855 10.5-4.133 2.762-5.29-4.437-3.024-4.57-2.918" }),
            React.createElement("g", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "7.346", transform: "matrix(-.13611 0 0 .13616 43.858 -19.924)" },
                React.createElement("path", { d: "M123.815 211.55c-3.902 20.625-15.045 22.081 18.215 39.285" }),
                React.createElement("path", { d: "M144.607 239.887c-21.218 4.29-4.238 20.43-4.238 20.43" })),
            React.createElement("g", { stroke: "#000", strokeLinejoin: "miter", strokeWidth: "7.346", transform: "matrix(.13611 0 0 .13616 8.088 -79.926)" },
                React.createElement("path", { fill: "none", fillRule: "evenodd", strokeLinecap: "butt", d: "M165.421 727.236l-5 65.358h-18.928" }),
                React.createElement("path", { fill: "#520", fillOpacity: "1", fillRule: "nonzero", strokeDashoffset: "0", strokeLinecap: "round", d: "M165.74 774.086l-10.113-1.072-2.239 14.624c-10.04-.388-18.99-2.681-20.14 9.786l33.652-.253z", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", opacity: "1", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" })),
            React.createElement("g", { fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "7.346", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", imageRendering: "auto", shapeRendering: "auto", textRendering: "auto", transform: "matrix(.1323 -.03203 .03202 .13234 -15.173 -71.598)", visibility: "visible" },
                React.createElement("rect", { width: "49.528", height: "77.358", x: "152.965", y: "650.977", fill: "#450", display: "inline", enableBackground: "accumulate", opacity: "1", overflow: "visible", rx: "2.141", ry: "2.138" }),
                React.createElement("rect", { width: "49.998", height: "20.328", x: "152.73", y: "708.242", fill: "#1a1a1a", display: "inline", enableBackground: "accumulate", opacity: "1", overflow: "visible", rx: "2.141", ry: "2.138" })),
            React.createElement("path", { d: "M36.082 25.164l-.206 1.016-.914-.49-4.234-8.896 1.12-.526z", fill: "#b3b3b3", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "#520", fillOpacity: "1", fillRule: "nonzero", stroke: "#520", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeWidth: "1", d: "M-21.838 -27.707H-20.789V-25.026H-21.838z", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", transform: "rotate(154.821) skewX(-.014)", visibility: "visible" }),
            React.createElement("path", { fill: "#a05a2c", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeWidth: "0.5", d: "M36.292 25.247l-.337 1.048-1.022-.41-4.11-8.603 1.359-.639z", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "#666", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeWidth: "0.5", d: "M-23.17 -28.383H-19.457V-27.489H-23.17z", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", transform: "rotate(154.821) skewX(-.014)", visibility: "visible" }),
            React.createElement("g", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "7.346", transform: "matrix(.13611 0 0 .13616 16.95 -19.924)" },
                React.createElement("path", { d: "M63.76 253.475c18.767 10.788-2.45 20.72-2.451 20.72" }),
                React.createElement("path", { d: "M112.199 208.014c19.67 8.125-11.644 57.76-48.74 56.035" })),
            React.createElement("path", { fill: "#222b00", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeWidth: "1", d: "M41.225 16.112l-1.294.548-.649.87-1.53.659-.776 1.022-.967.25-.375.691-.818.075-.46.895c-.41-.517-.447-1.28-.81-1.756-4.306-5.63-4.15-7.885-4.868-8.126-2.247-.756-2.562-2.405 3.496-3.52.772-.143 2.085 1.902 2.98 2.59 1.238.952 2.892 2.018 3.963 3.91.385.681 1.778 1.082 2.108 1.892z", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("ellipse", { cx: "27.927", cy: "5.243", fill: "#e9c6af", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", rx: "4.549", ry: "4.55", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("ellipse", { cx: "26.607", cy: "4.375", fill: "#000", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", rx: "0.378", ry: "0.612", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("ellipse", { cx: "24.751", cy: "4.375", fill: "#000", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", rx: "0.378", ry: "0.612", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("ellipse", { cx: "26.237", cy: "7.622", fill: "#000", fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", rx: "0.962", ry: "0.585", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "#520", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "1", d: "M24.469 1.334c-1.59 1.92-1.032 2.238.772 1.144 1.076-.26 2.35.176 2.548 1.252.544.872 1.636 1.366 1.8 2.464.267.913.67 1.793.882 2.717.397.762 1.545.997 1.853.04.405-.509.84-1.195 1.44-1.081.034-.93-.01-1.896-.478-2.726-.123-.857-.205-1.808-1.057-2.276-.693-.593-.56-1.663-1.5-2.048-.897-.865-1.883-.627-3.044-.691-1.577.076-2.49.691-3.216 1.205z" }),
            React.createElement("path", { fill: "#520", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeWidth: "1", d: "M23.938 42.444l-2.585.01c.718-2.11 2.526-2.072 2.585-.01z", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "1", d: "M25.822 29.71l-1.412 7.499 3.268 4.544" }),
            React.createElement("path", { d: "M29.19 41.421l-1.302 2.234c-1.453-1.688-.505-3.227 1.302-2.234z", fill: "#520", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeLinecap: "butt", strokeLinejoin: "miter", strokeWidth: "1", d: "M41.038 29.564l3.37 6.854-2.252 7.199" }),
            React.createElement("path", { fill: "#520", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeWidth: "1", d: "M40.018 43.576l-2.366-1.043c1.514-1.633 3.15-.864 2.366 1.043z", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }),
            React.createElement("path", { d: "M43.214 44.862l-2.585.009c.719-2.108 2.526-2.071 2.585-.01z", fill: "#520", fillOpacity: "1", fillRule: "nonzero", stroke: "#000", strokeDashoffset: "0", strokeLinecap: "round", strokeLinejoin: "miter", strokeWidth: "1", clipRule: "nonzero", color: "#000", colorInterpolation: "sRGB", colorInterpolationFilters: "linearRGB", colorRendering: "auto", display: "inline", enableBackground: "accumulate", imageRendering: "auto", overflow: "visible", shapeRendering: "auto", textRendering: "auto", visibility: "visible" }))));
}
