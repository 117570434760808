'use client';
import React from 'react';
import Hex from './Hex.js';
function Map({ hexProperties, map = [], onHexClick = () => { }, getTerrainHex = () => null, }) {
    const hexes = [];
    for (let colIdx = 0; colIdx < map.length; colIdx++) {
        const rowLength = (map[colIdx] && map[colIdx].length) || 0;
        for (let rowIdx = 0; rowIdx < rowLength; rowIdx++) {
            const { row, column, terrainId, graphicalVariant } = map[colIdx][rowIdx];
            const center = hexProperties.calculateCenterPos(column, row);
            const colId = `${column}`.padStart(2, '0');
            const rowId = `${row}`.padStart(2, '0');
            const hexId = `${colId}${rowId}`;
            const CurrentHex = getTerrainHex(terrainId) || Hex;
            hexes.push(React.createElement(CurrentHex, { center: center, size: hexProperties.size, key: `hex-${column}-${row}`, id: hexId, isFlatTop: hexProperties.isFlatTop, dimensions: {
                    width: hexProperties.width,
                    height: hexProperties.height,
                }, onClick: () => onHexClick(column, row), graphicalVariant: graphicalVariant }));
        }
    }
    return React.createElement("g", null, hexes);
}
export default Map;
