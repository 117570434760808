import React from 'react';
import Hex from '../../map/Hex.js';
import Single1 from './variant/Single1.js';
import { createVariants } from '../utils/withRotation.js';
import Full1 from './variant/Full.js';
import OneAdjacent1 from './variant/OneAdjacent1.js';
import TwoAdjacents1 from './variant/TwoAdjacents1.js';
import ThreeAdjacents1 from './variant/ThreeAdjacents1.js';
import FourAdjacents1 from './variant/FourAdjacents1.js';
import FiveAdjacents1 from './variant/FiveAdjacents1.js';
import TreeLine1 from './variant/TreeLine1.js';
import YShape1 from './variant/YShape1.js';
import YShape3 from './variant/YShape3.js';
import YShape2 from './variant/YShape2.js';
import { TERRAIN_COLOR_PLAIN } from '../colors.js';
const variants = {
    default: Full1,
    ...createVariants('single1', Single1),
    ...createVariants('oneAdjacent1', OneAdjacent1),
    ...createVariants('twoAdjacents1', TwoAdjacents1),
    ...createVariants('threeAdjacents1', ThreeAdjacents1),
    ...createVariants('fourAdjacents1', FourAdjacents1),
    ...createVariants('fiveAdjacents1', FiveAdjacents1),
    ...createVariants('treeLine1', TreeLine1),
    ...createVariants('yShape1', YShape1),
    ...createVariants('yShape2', YShape2),
    ...createVariants('yShape3', YShape3),
};
function WoodHex({ style = {}, children, ...props }) {
    let variantName = props.graphicalVariant ?? 'default';
    if (variants[variantName] === undefined) {
        variantName = 'default';
    }
    let Variant = variants[variantName] ?? null;
    if (variantName === 'default') {
        return (React.createElement(Hex, { ...props, style: {
                ...style,
                fill: 'green',
            } },
            React.createElement(Full1, { ...props }),
            children));
    }
    return (React.createElement(Hex, { ...props, style: {
            ...style,
            fill: TERRAIN_COLOR_PLAIN,
        } },
        React.createElement(Variant, { ...props }),
        children));
}
export const graphicalVariants = Object.keys(variants);
export default WoodHex;
