import React from 'react';
import Hex from '../../map/Hex.js';
import Straight1 from './variant/Straight1.js';
import Straight2 from './variant/Straight2.js';
import Turn1 from './variant/Turn1.js';
import { createVariants } from '../utils/withRotation.js';
import HardTurn1 from './variant/HardTurn1.js';
import Fork1 from './variant/Fork1.js';
import { TERRAIN_COLOR_PLAIN } from '../colors.js';
const variants = {
    default: Straight1,
    ...createVariants('straight1', Straight1),
    ...createVariants('straight2', Straight2),
    ...createVariants('turn1', Turn1),
    ...createVariants('hardTurn1', HardTurn1),
    ...createVariants('fork1', Fork1),
};
function RiverHex(props) {
    const style = (props && props.style) || {};
    const Variant = variants[props.graphicalVariant ?? 'default'];
    return (React.createElement(Hex, { ...props, style: {
            ...style,
            fill: TERRAIN_COLOR_PLAIN,
        } },
        React.createElement(Variant, { ...props }),
        props.children));
}
export const graphicalVariants = Object.keys(variants);
export default RiverHex;
