import React from 'react';
function withRotation(angle, Component) {
    return function (props) {
        return (React.createElement("g", { transform: `rotate(${angle}, ${props.center.x}, ${props.center.y})` },
            React.createElement(Component, { ...props })));
    };
}
export function createVariants(name, Component) {
    const variants = {};
    const angles = [0, 60, 120, 180, 240, 300];
    angles.forEach((angle) => {
        if (angle === 0) {
            variants[name] = Component;
        }
        else {
            variants[`${name}Angle${angle}`] = withRotation(angle, Component);
        }
    });
    return variants;
}
export default withRotation;
