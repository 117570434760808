export const ORIENTATION_CLOCKWISE = 1;
export const ORIENTATION_COUNTERCLOCKWISE = 2;
export const ORIENTATION_COLLINEAR = 3;
export const getTangensDiff = (a, b, c, precision = 4) => {
    const tangensDiff = (b.y - a.y) * (c.x - b.x) - (c.y - b.y) * (b.x - a.x);
    return parseFloat(tangensDiff.toFixed(precision));
};
export const calcPointsOrientation = (a, b, c, precision = 4) => {
    const tangensDiff = getTangensDiff(a, b, c, precision);
    if (tangensDiff === 0) {
        return ORIENTATION_COLLINEAR;
    }
    if (tangensDiff > 0) {
        return ORIENTATION_CLOCKWISE;
    }
    return ORIENTATION_COUNTERCLOCKWISE;
};
const round = (num) => num;
const isPointOnLineSegmentWhenCollinear = (segment, point) => {
    if (round(point.x) <= Math.max(round(segment.start.x), round(segment.end.x)) &&
        round(point.x) >= Math.min(round(segment.start.x), round(segment.end.x)) &&
        round(point.y) <= Math.max(round(segment.start.y), round(segment.end.y)) &&
        round(point.y) >= Math.min(round(segment.start.y), round(segment.end.y))) {
        return true;
    }
    return false;
};
export const isPointOnLineSegment = (segment, point, precision = 2) => {
    const orientation = calcPointsOrientation(segment.start, segment.end, point, precision);
    if (orientation !== ORIENTATION_COLLINEAR) {
        return false;
    }
    return isPointOnLineSegmentWhenCollinear(segment, point);
};
export const doesLineSegmentsIntersect = (segment1, segment2, precision = 2) => {
    const orientation1 = calcPointsOrientation(segment1.start, segment1.end, segment2.start, precision);
    const orientation2 = calcPointsOrientation(segment1.start, segment1.end, segment2.end, precision);
    const orientation3 = calcPointsOrientation(segment2.start, segment2.end, segment1.start, precision);
    const orientation4 = calcPointsOrientation(segment2.start, segment2.end, segment1.end, precision);
    if (orientation1 !== orientation2 && orientation3 !== orientation4) {
        return true;
    }
    if (orientation1 === ORIENTATION_COLLINEAR &&
        isPointOnLineSegmentWhenCollinear(segment1, segment2.start)) {
        return true;
    }
    if (orientation2 === ORIENTATION_COLLINEAR &&
        isPointOnLineSegmentWhenCollinear(segment1, segment2.end)) {
        return true;
    }
    if (orientation3 === ORIENTATION_COLLINEAR &&
        isPointOnLineSegmentWhenCollinear(segment2, segment1.start)) {
        return true;
    }
    if (orientation4 === ORIENTATION_COLLINEAR &&
        isPointOnLineSegmentWhenCollinear(segment2, segment1.end)) {
        return true;
    }
    return false;
};
