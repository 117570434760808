import { calcPointsOrientation, ORIENTATION_CLOCKWISE, ORIENTATION_COLLINEAR, ORIENTATION_COUNTERCLOCKWISE, } from './geometry';
export const isSamePosition = (position1, position2) => {
    if (!position1 || !position2) {
        return false;
    }
    return position1.column === position2.column && position1.row === position2.row;
};
export const hexCorner = (center, size, i, isFlatTop = true) => {
    const angleDeg = 60 * i + (isFlatTop ? 0 : 30);
    const angleRad = (Math.PI / 180) * angleDeg;
    return {
        x: center.x + size * Math.cos(angleRad),
        y: center.y + size * Math.sin(angleRad),
    };
};
export const getDimensions = (size, isFlatTop = true) => {
    const dim1 = size * 2;
    //const dim2 = (Math.sqrt(3) / 2) * dim1;
    const dim2 = Math.sqrt(3) * size;
    return {
        height: isFlatTop ? dim2 : dim1,
        width: isFlatTop ? dim1 : dim2,
    };
};
export const createCenterPosCalculator = ({ start, size, isFlatTop = true, isFirstOffsetted = false, }) => {
    const dimensions = getDimensions(size, isFlatTop);
    const offsetFlag = isFirstOffsetted ? 1 : 0;
    if (isFlatTop) {
        return (column, row) => {
            return {
                x: start.x + (column * dimensions.width * 3) / 4,
                y: start.y +
                    row * dimensions.height +
                    (column % 2 === offsetFlag ? 0 : dimensions.height / 2),
            };
        };
    }
    return (column, row) => {
        return {
            x: start.x +
                column * dimensions.width +
                (row % 2 === offsetFlag ? 0 : dimensions.width / 2),
            y: start.y + (row * dimensions.height * 3) / 4,
        };
    };
};
export const getNeighboursPositionDiffs = ({ position, isFlatTop = true, isFirstOffsetted = false }) => {
    if (isFlatTop) {
        const isOdd = position.column % 2;
        if ((isOdd && !isFirstOffsetted) || (!isOdd && isFirstOffsetted)) {
            return [
                { column: 1, row: 1, angle: 30 },
                { column: 0, row: 1, angle: 90 },
                { column: -1, row: 1, angle: 150 },
                { column: -1, row: 0, angle: 210 },
                { column: 0, row: -1, angle: 270 },
                { column: 1, row: 0, angle: 330 },
            ];
        }
        else {
            return [
                { column: 1, row: 0, angle: 30 },
                { column: 0, row: 1, angle: 90 },
                { column: -1, row: 0, angle: 150 },
                { column: -1, row: -1, angle: 210 },
                { column: 0, row: -1, angle: 270 },
                { column: 1, row: -1, angle: 330 },
            ];
        }
    }
    else {
        const isOdd = position.row % 2;
        if ((isOdd && !isFirstOffsetted) || (!isOdd && isFirstOffsetted)) {
            return [
                { column: 1, row: 1, angle: 60 },
                { column: 0, row: 1, angle: 120 },
                { column: -1, row: 0, angle: 180 },
                { column: 0, row: -1, angle: 240 },
                { column: 1, row: -1, angle: 300 },
                { column: 1, row: 0, angle: 0 },
            ];
        }
        else {
            return [
                { column: 0, row: 1, angle: 60 },
                { column: -1, row: 1, angle: 120 },
                { column: -1, row: 0, angle: 180 },
                { column: -1, row: -1, angle: 240 },
                { column: 0, row: -1, angle: 300 },
                { column: 1, row: 0, angle: 0 },
            ];
        }
    }
};
export const getNeighbours = ({ position, isFlatTop = true, isFirstOffsetted = false }) => {
    const positionsDiffs = getNeighboursPositionDiffs({ position, isFlatTop, isFirstOffsetted });
    return positionsDiffs.map((diff) => {
        return {
            column: position.column + diff.column,
            row: position.row + diff.row,
            angle: diff.angle,
        };
    });
};
export const getAngleBetweenNeighbours = ({ fromPosition, toPosition, isFlatTop = true, isFirstOffsetted = false, }) => {
    if (isFlatTop) {
        const isOdd = fromPosition.column % 2;
        if ((isOdd && !isFirstOffsetted) || (!isOdd && isFirstOffsetted)) {
            if (toPosition.column === fromPosition.column &&
                toPosition.row === fromPosition.row - 1) {
                return 270;
            }
            if (toPosition.column === fromPosition.column + 1 &&
                toPosition.row === fromPosition.row) {
                return 330;
            }
            if (toPosition.column === fromPosition.column + 1 &&
                toPosition.row === fromPosition.row + 1) {
                return 30;
            }
            if (toPosition.column === fromPosition.column &&
                toPosition.row === fromPosition.row + 1) {
                return 90;
            }
            if (toPosition.column === fromPosition.column - 1 &&
                toPosition.row === fromPosition.row + 1) {
                return 150;
            }
            if (toPosition.column === fromPosition.column - 1 &&
                toPosition.row === fromPosition.row) {
                return 210;
            }
        }
        else {
            if (toPosition.column === fromPosition.column &&
                toPosition.row === fromPosition.row - 1) {
                return 270;
            }
            if (toPosition.column === fromPosition.column + 1 &&
                toPosition.row === fromPosition.row - 1) {
                return 330;
            }
            if (toPosition.column === fromPosition.column + 1 &&
                toPosition.row === fromPosition.row) {
                return 30;
            }
            if (toPosition.column === fromPosition.column &&
                toPosition.row === fromPosition.row + 1) {
                return 90;
            }
            if (toPosition.column === fromPosition.column - 1 &&
                toPosition.row === fromPosition.row) {
                return 150;
            }
            if (toPosition.column === fromPosition.column - 1 &&
                toPosition.row === fromPosition.row - 1) {
                return 210;
            }
        }
    }
    else if (!isFlatTop) {
        const isOdd = fromPosition.row % 2;
        if ((isOdd && !isFirstOffsetted) || (!isOdd && isFirstOffsetted)) {
            if (toPosition.column === fromPosition.column &&
                toPosition.row === fromPosition.row - 1) {
                return 240;
            }
            if (toPosition.column === fromPosition.column + 1 &&
                toPosition.row === fromPosition.row - 1) {
                return 300;
            }
            if (toPosition.column === fromPosition.column + 1 &&
                toPosition.row === fromPosition.row) {
                return 0;
            }
            if (toPosition.column === fromPosition.column + 1 &&
                toPosition.row === fromPosition.row + 1) {
                return 60;
            }
            if (toPosition.column === fromPosition.column &&
                toPosition.row === fromPosition.row + 1) {
                return 120;
            }
            if (toPosition.column === fromPosition.column - 1 &&
                toPosition.row === fromPosition.row) {
                return 180;
            }
        }
        else {
            if (toPosition.column === fromPosition.column &&
                toPosition.row === fromPosition.row - 1) {
                return 300;
            }
            if (toPosition.column === fromPosition.column + 1 &&
                toPosition.row === fromPosition.row) {
                return 0;
            }
            if (toPosition.column === fromPosition.column &&
                toPosition.row === fromPosition.row + 1) {
                return 60;
            }
            if (toPosition.column === fromPosition.column - 1 &&
                toPosition.row === fromPosition.row + 1) {
                return 120;
            }
            if (toPosition.column === fromPosition.column - 1 &&
                toPosition.row === fromPosition.row) {
                return 180;
            }
            if (toPosition.column === fromPosition.column - 1 &&
                toPosition.row === fromPosition.row - 1) {
                return 240;
            }
        }
    }
    return null;
};
export const areNeighbours = (positionA, positionB, { isFlatTop, isFirstOffsetted }) => {
    const neighbours = getNeighbours({ position: positionA, isFlatTop, isFirstOffsetted });
    return !!neighbours.find((n) => n.column === positionB.column && n.row === positionB.row);
};
export const calcMapDimensions = (width, height, hexProperties, hasSameLengths = true, borderWidth = 1) => {
    const hexWidth = hexProperties.width + borderWidth / 2;
    const hexHeight = hexProperties.height + borderWidth / 2;
    const mapWidth = hexProperties.isFlatTop
        ? ((hexWidth * 3) / 4) * width + (hexWidth * 1) / 4
        : hexWidth * width + (hasSameLengths ? hexWidth / 2 : 0);
    const mapHeight = hexProperties.isFlatTop
        ? hexHeight * height + (hasSameLengths ? hexHeight / 2 : 0)
        : ((hexHeight * 3) / 4) * height + (hexHeight * 1) / 4;
    return {
        width: mapWidth,
        height: mapHeight,
    };
};
export function getHexSizeToFitWholeMap({ isFlatTop, columns, containerWidth, defaultHexSize = 50, hexBorderWidth = 1, hasSameLengths = true, }) {
    let hexWidth = 0;
    let hexSize = defaultHexSize;
    if (isFlatTop) {
        // mapWidth == ((hexWidth * 3) / 4) * columns + (hexWidth * 1) / 4;
        // mapWidth == ((hexWidth * 3 * columns) / 4) + hexWidth / 4;
        // mapWidth == (hexWidth * 3 * columns + hexWidth) / 4
        // mapWidth * 4 == hexWidth * (3 * columns + 1)
        // hexWidth == (mapWidth * 4) / (3 * columns + 1)
        hexWidth = (containerWidth * 4) / (3 * columns + 1);
        // hexWidth == hexSize * 2
        hexSize = hexWidth / 2;
    }
    else {
        if (hasSameLengths) {
            // mapWidth == hexWidth * columns + hexWidth / 2;
            // mapWidth == hexWidth * columns + hexWidth * 1/2
            // mapWidth == hexWidth * (columns + 1/2)
            // hexWidth == mapWidth / (columns + 1/2)
            hexWidth = containerWidth / (columns + 0.5);
        }
        else {
            // mapWidth == hexWidth * columns;
            hexWidth = containerWidth / columns - hexBorderWidth / 2;
        }
        // hexWidth == Math.sqrt(3) * hexSize;
        hexSize = hexWidth / Math.sqrt(3);
    }
    if (hexSize > defaultHexSize) {
        hexSize = defaultHexSize;
    }
    return hexSize;
}
const createHexProperties = ({ hexSize = 50, isFlatTop = false, isFirstOffsetted = true, }) => {
    const hexDimensions = getDimensions(hexSize, isFlatTop);
    const calculateCenterPos = createCenterPosCalculator({
        start: {
            x: isFlatTop ? hexSize : hexDimensions.width / 2,
            y: isFlatTop ? hexDimensions.height / 2 : hexSize,
        },
        isFlatTop,
        size: hexSize,
        isFirstOffsetted,
    });
    const transformToHexaCoor = ({ column, row }) => {
        if (isFlatTop) {
            if (isFirstOffsetted) {
                return {
                    column: row - Math.ceil(column / 2),
                    row: row + Math.floor(column / 2),
                };
            }
            else {
                return {
                    column: row - Math.floor(column / 2),
                    row: row + Math.ceil(column / 2),
                };
            }
        }
        if (isFirstOffsetted) {
            return {
                column: column - Math.ceil(row / 2),
                row: column + Math.floor(row / 2),
            };
        }
        else {
            return {
                column: column - Math.floor(row / 2),
                row: column + Math.ceil(row / 2),
            };
        }
    };
    const transformToArrayCoor = ({ column, row }) => {
        if (isFlatTop) {
            if (isFirstOffsetted) {
                return {
                    column: row - column,
                    row: Math.ceil((row + column) / 2),
                };
            }
            else {
                return {
                    column: row - column,
                    row: Math.floor((row + column) / 2),
                };
            }
        }
        if (isFirstOffsetted) {
            return {
                column: Math.ceil((column + row) / 2),
                row: row - column,
            };
        }
        else {
            return {
                column: Math.floor((column + row) / 2),
                row: row - column,
            };
        }
    };
    const hexDistance = (pos1InHexaCoor, pos2InHexaCoor) => {
        const dx = pos2InHexaCoor.column - pos1InHexaCoor.column;
        const dy = pos2InHexaCoor.row - pos1InHexaCoor.row;
        return (Math.abs(dx) + Math.abs(dy) + Math.abs(dx - dy)) / 2;
    };
    const doesHexIntersectLine = (hex, lineSegment) => {
        const center = calculateCenterPos(hex.column, hex.row);
        const orientation1 = calcPointsOrientation(lineSegment.start, lineSegment.end, hexCorner(center, hexSize, 0, isFlatTop));
        if (orientation1 === ORIENTATION_COLLINEAR) {
            return true;
        }
        for (let cornerIndex = 1; cornerIndex <= 5; cornerIndex += 1) {
            const orientation = calcPointsOrientation(lineSegment.start, lineSegment.end, hexCorner(center, hexSize, cornerIndex, isFlatTop));
            if (orientation === ORIENTATION_COLLINEAR) {
                return true;
            }
            if (orientation !== orientation1) {
                return true;
            }
        }
        return false;
    };
    const getHexOrientation = (hex, lineSegment) => {
        const orientations = {
            [ORIENTATION_COLLINEAR]: 0,
            [ORIENTATION_CLOCKWISE]: 0,
            [ORIENTATION_COUNTERCLOCKWISE]: 0,
        };
        const center = calculateCenterPos(hex.column, hex.row);
        for (let cornerIndex = 0; cornerIndex < 6; cornerIndex += 1) {
            const orientation = calcPointsOrientation(lineSegment.start, lineSegment.end, hexCorner(center, hexSize, cornerIndex, isFlatTop));
            orientations[orientation] += 1;
        }
        if (orientations[ORIENTATION_CLOCKWISE] === 0 &&
            orientations[ORIENTATION_COUNTERCLOCKWISE] > 0) {
            return ORIENTATION_COUNTERCLOCKWISE;
        }
        if (orientations[ORIENTATION_COUNTERCLOCKWISE] === 0 &&
            orientations[ORIENTATION_CLOCKWISE] > 0) {
            return ORIENTATION_CLOCKWISE;
        }
        // not really collinear because line goes directly through the hex
        return ORIENTATION_COLLINEAR;
    };
    const getDistance = (position1, position2) => {
        return hexDistance(transformToHexaCoor(position1), transformToHexaCoor(position2));
    };
    const isSamePosition = (position1, position2) => {
        if (!position1 || !position2) {
            return false;
        }
        return position1.column === position2.column && position1.row === position2.row;
    };
    const getNeighboursPositionDiffsInHexaCoor = () => {
        if (isFlatTop) {
            // must be ordered by cornerIndex
            return [
                { column: 0, row: 1, angle: 30, cornerIndex: 0 },
                { column: 1, row: 1, angle: 90, cornerIndex: 1 },
                { column: 1, row: 0, angle: 150, cornerIndex: 2 },
                { column: 0, row: -1, angle: 210, cornerIndex: 3 },
                { column: -1, row: -1, angle: 270, cornerIndex: 4 },
                { column: -1, row: 0, angle: 330, cornerIndex: 5 },
            ];
        }
        // must be ordered by cornerIndex
        return [
            { column: 0, row: 1, angle: 60, cornerIndex: 0 },
            { column: -1, row: 0, angle: 120, cornerIndex: 1 },
            { column: -1, row: -1, angle: 180, cornerIndex: 2 },
            { column: 0, row: -1, angle: 240, cornerIndex: 3 },
            { column: 1, row: 0, angle: 300, cornerIndex: 4 },
            { column: 1, row: 1, angle: 0, cornerIndex: 5 },
        ];
    };
    /**
     * Clark Verbrugge's algorithm for LOS by Intersection of Hexagons with a Straight Line:
     * http://www-cs-students.stanford.edu/~amitp/Articles/HexLOS.html
     * http://www.sable.mcgill.ca/~clump/hexes.txt
     */
    const getHexesInLos = (sourceHex, targetHex) => {
        const centerStart = calculateCenterPos(sourceHex.column, sourceHex.row);
        const centerEnd = calculateCenterPos(targetHex.column, targetHex.row);
        const lineSegment = {
            start: centerStart,
            end: centerEnd,
        };
        const sourceInHexaCoor = transformToHexaCoor(sourceHex);
        const targetInHexaCoor = transformToHexaCoor(targetHex);
        const distance = hexDistance(sourceInHexaCoor, targetInHexaCoor);
        let current1 = sourceInHexaCoor;
        let current2 = null;
        let previous1 = null;
        let previous2 = null;
        const path = [];
        do {
            if (current1 && current2) {
                path.push([transformToArrayCoor(current1), transformToArrayCoor(current2)]);
            }
            else if (current1) {
                path.push([transformToArrayCoor(current1)]);
            }
            else if (current2) {
                path.push([transformToArrayCoor(current2)]);
            }
            if (isSamePosition(current1, targetInHexaCoor)) {
                break;
            }
            if (current1 === null) {
                break;
            }
            const nextCandidates = [
                ...getNextHexesByHexaCoor(current1, current2, previous1, previous2, lineSegment, targetInHexaCoor, distance),
            ];
            if (current2) {
                // this call is important to find next hexes when the line intersects
                // the vertex and is not collinear with the hex edge
                nextCandidates.push(...getNextHexesByHexaCoor(current2, current1, previous1, previous2, lineSegment, targetInHexaCoor, distance));
            }
            const next = [];
            nextCandidates.forEach((candidate) => {
                // skip empty
                if (candidate === null) {
                    return;
                }
                // skip duplicates
                if (next.find((n) => isSamePosition(n, candidate))) {
                    return;
                }
                next.push(candidate);
            });
            const [next1, next2] = next;
            previous1 = current1;
            previous2 = current2;
            current1 = next1;
            current2 = next2;
            //eslint-disable-next-line no-constant-condition
        } while (true);
        path.shift();
        return path;
    };
    const getNextHexesByHexaCoor = (current1, current2, previous1, previous2, lineSegment, targetInHexaCoor, wholeDistance) => {
        const neighboursPositionDiffs = getNeighboursPositionDiffsInHexaCoor();
        let next1 = null;
        let next2 = null;
        const cur1InArrayCoor = transformToArrayCoor(current1);
        const centerCur1 = calculateCenterPos(cur1InArrayCoor.column, cur1InArrayCoor.row);
        const isValidCandidate = (hexInHexaCoor) => {
            if (!isSamePosition(hexInHexaCoor, current1) &&
                !isSamePosition(hexInHexaCoor, current2) &&
                !isSamePosition(hexInHexaCoor, next1) &&
                !isSamePosition(hexInHexaCoor, next2) &&
                !isSamePosition(hexInHexaCoor, previous1) &&
                !isSamePosition(hexInHexaCoor, previous2) &&
                hexDistance(hexInHexaCoor, targetInHexaCoor) <= wholeDistance
            //!onlyOneVertexIntersectsLine(transformToArrayCoor(hexInHexaCoor), lineSegment)
            ) {
                return true;
            }
            return false;
        };
        for (let cornerIndex = 0; cornerIndex < 6; cornerIndex += 1) {
            const orientation1 = calcPointsOrientation(lineSegment.start, lineSegment.end, hexCorner(centerCur1, hexSize, cornerIndex, isFlatTop));
            const orientation2 = calcPointsOrientation(lineSegment.start, lineSegment.end, hexCorner(centerCur1, hexSize, ((cornerIndex + 1) % 6), isFlatTop));
            if (orientation1 === ORIENTATION_COLLINEAR ||
                orientation2 === ORIENTATION_COLLINEAR ||
                orientation1 !== orientation2) {
                // in these cases we have to consider hexagon adjacent to edge (cornerIndex, cornerIndex+1)
                const hexInHexaCoor = {
                    column: current1.column + neighboursPositionDiffs[cornerIndex].column,
                    row: current1.row + neighboursPositionDiffs[cornerIndex].row,
                };
                if (isValidCandidate(hexInHexaCoor)) {
                    if (next1 === null) {
                        next1 = hexInHexaCoor;
                    }
                    else if (next2 === null) {
                        next2 = hexInHexaCoor;
                    }
                }
                // current corner lies on the lineSegment
                if (orientation1 === ORIENTATION_COLLINEAR) {
                    // hex next to the edge (cornerIndex-1, cornerIndex)
                    const hexInHexaCoor = {
                        column: current1.column + neighboursPositionDiffs[(cornerIndex + 5) % 6].column,
                        row: current1.row + neighboursPositionDiffs[(cornerIndex + 5) % 6].row,
                    };
                    if (isValidCandidate(hexInHexaCoor)) {
                        if (next1 === null) {
                            next1 = hexInHexaCoor;
                        }
                        else if (next2 === null) {
                            next2 = hexInHexaCoor;
                        }
                    }
                }
                // next corner (cornerIndex+1) lies on the lineSegment
                if (orientation2 === ORIENTATION_COLLINEAR) {
                    // hex next to the edge (cornerIndex+1, cornerIndex+2)
                    const hexInHexaCoor = {
                        column: current1.column + neighboursPositionDiffs[(cornerIndex + 1) % 6].column,
                        row: current1.row + neighboursPositionDiffs[(cornerIndex + 1) % 6].row,
                    };
                    if (isValidCandidate(hexInHexaCoor)) {
                        if (next1 === null) {
                            next1 = hexInHexaCoor;
                        }
                        else if (next2 === null) {
                            next2 = hexInHexaCoor;
                        }
                    }
                }
            }
        }
        return [next1, next2];
    };
    const isLosUnobstructed = (fromPosition, toPosition, hasObstruction = (_) => false) => {
        const centerStart = calculateCenterPos(fromPosition.column, fromPosition.row);
        const centerEnd = calculateCenterPos(toPosition.column, toPosition.row);
        const lineSegment = {
            start: centerStart,
            end: centerEnd,
        };
        const losPath = getHexesInLos(fromPosition, toPosition);
        const blockedSides = {
            [ORIENTATION_CLOCKWISE]: false,
            [ORIENTATION_COUNTERCLOCKWISE]: false,
        };
        for (let i = 0; i < losPath.length; i += 1) {
            const leg = losPath[i];
            if (leg.length === 1) {
                if (isSamePosition(leg[0], toPosition)) {
                    break;
                }
                if (hasObstruction(leg[0])) {
                    return false;
                }
            }
            else if (leg.length === 2) {
                for (const hex of leg) {
                    if (hasObstruction(hex)) {
                        const hexOrientation = getHexOrientation(hex, lineSegment);
                        if (hexOrientation === ORIENTATION_COLLINEAR) {
                            return false;
                        }
                        blockedSides[hexOrientation] = true;
                        if (blockedSides[ORIENTATION_CLOCKWISE] &&
                            blockedSides[ORIENTATION_COUNTERCLOCKWISE]) {
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    };
    return {
        size: hexSize,
        width: hexDimensions.width,
        height: hexDimensions.height,
        isFlatTop,
        isFirstOffsetted,
        calculateCenterPos,
        /* calculateCenterPos: createCenterPosCalculator({ */
        /*     start: { */
        /*         x: isFlatTop ? hexSize : hexDimensions.width / 2, */
        /*         y: isFlatTop ? hexDimensions.height / 2 : hexSize, */
        /*     }, */
        /*     isFlatTop, */
        /*     size: hexSize, */
        /*     isFirstOffsetted, */
        /* }), */
        transformToHexaCoor,
        transformToArrayCoor,
        getDistance,
        doesHexIntersectLine,
        getHexesInLos,
        isLosUnobstructed,
    };
};
export default createHexProperties;
