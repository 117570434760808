import React from 'react';
import { CombatEffectSymbol } from '@wareena/grid-battles-entity/game/codebook/dice';
function DropIcon({ origin, width, height, color = 'red', }) {
    const q1Command = `Q ${origin.x + (width * 5) / 6},${origin.y + height / 3} ${origin.x + width},${origin.y + height / 1.5}`;
    const aCommand = `A ${width / 3},${height / 4} 0,0,1 ${origin.x},${origin.y + height / 1.5}`;
    const q2Command = `Q ${origin.x + width / 6},${origin.y + height / 3} ${origin.x + width / 2},${origin.y}`;
    return (React.createElement("g", { className: "damage-symbol" },
        React.createElement("path", { strokeWidth: 1, stroke: "black", fill: color, d: `M ${origin.x + width / 2},${origin.y} ${q1Command} ${aCommand} ${q2Command} Z` })));
}
function DamageSymbol({ origin, size }) {
    return (React.createElement("g", { className: "damage-symbol" },
        React.createElement("path", { stroke: "black", fill: "#a00", strokeWidth: 1, d: `M ${origin.x},${origin.y + size / 3} A ${size / 4},${size / 4} 0,0,1 ${origin.x + size / 2},${origin.y + size / 3} A ${size / 4},${size / 4} 0,0,1 ${origin.x + size},${origin.y + size / 3} Q ${origin.x + size},${origin.y + size / 1.5} ${origin.x + size / 2},${origin.y + size} Q ${origin.x},${origin.y + size / 1.5} ${origin.x},${origin.y + size / 3}
                Z ` })));
}
function BonusSymbol({ origin, size }) {
    const largeDropHeight = size;
    const smallDropHeight = size / 1.5;
    return (React.createElement("g", { className: "bonus-symbol" },
        React.createElement(DropIcon, { width: largeDropHeight / 1.5, height: largeDropHeight, origin: { x: origin.x + size / 3, y: origin.y } }),
        React.createElement(DropIcon, { width: smallDropHeight / 1.5, height: smallDropHeight, origin: { x: origin.x, y: origin.y } })));
}
function ShieldSymbol({ origin, size }) {
    const curveControl1 = {
        x: (-1 * size) / 10,
        y: 0,
    };
    const curveControl2 = {
        x: (-1 * size) / 2,
        y: -size / 5,
    };
    const curveDestination = {
        x: (-1 * size) / 2,
        y: (-1 * size) / 2,
    };
    const curveLeft = `c ${curveControl1.x},${curveControl1.y} ${curveControl2.x},${curveControl2.y} ${curveDestination.x},${curveDestination.y}`;
    const curveRight = `c ${-1 * curveControl1.x},${curveControl1.y} ${-1 * curveControl2.x},${curveControl2.y} ${-1 * curveDestination.x},${curveDestination.y}`;
    return (React.createElement("g", { className: "shield-symbol" },
        React.createElement("path", { d: `M ${origin.x},${origin.y} h ${size / 2} v ${size} ${curveLeft} Z`, strokeWidth: 1, strokeLinejoin: "round", strokeLinecap: "round", stroke: "black", fill: "#80e5ff" }),
        React.createElement("path", { d: `M ${origin.x + size},${origin.y} h ${(-1 * size) / 2} v ${size} ${curveRight} Z`, strokeWidth: 1, strokeLinejoin: "round", strokeLinecap: "round", stroke: "black", fill: "#00aad4" })));
}
export default function SymbolIcon({ symbol, origin, size, }) {
    const symbolIcons = {
        [CombatEffectSymbol.Damage]: DamageSymbol,
        [CombatEffectSymbol.Bonus]: BonusSymbol,
        [CombatEffectSymbol.Shield]: ShieldSymbol,
    };
    const Icon = symbolIcons[symbol];
    if (!Icon) {
        return null;
    }
    return React.createElement(Icon, { origin: origin, size: size });
}
