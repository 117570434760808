import { CANONICAL_HEX_SIZE } from '../../../../codebook.js';
import React from 'react';
export default function Stone1({ center, dimensions, size, ...props }) {
    return (React.createElement("g", { transform: `translate(${center.x - dimensions.width / 2} ${center.y - dimensions.height / 2}) scale(${size / CANONICAL_HEX_SIZE})` }, props.isFlatTop ? (React.createElement("g", null,
        React.createElement("path", { fill: "#1e90ff", fillOpacity: "1", stroke: "#000", strokeWidth: "1", d: "M37.5 0h25c6.193 35.493 13.759 47.236 0 86.603h-25c3.505-42.79 5.816-25.585 0-86.603z" }),
        React.createElement("g", { fillOpacity: "1", stroke: "#000", strokeDasharray: "none", strokeLinecap: "round", strokeLinejoin: "miter", strokeMiterlimit: "4", strokeWidth: "0.129", transform: "rotate(90)" },
            React.createElement("path", { fill: "gray", d: "M50.767 -78.08H53.457V-72.884H50.767z" }),
            React.createElement("path", { fill: "gray", d: "M50.767 -72.883H53.457V-67.687H50.767z" }),
            React.createElement("path", { fill: "gray", d: "M50.767 -67.687H53.457V-62.491H50.767z" }),
            React.createElement("path", { fill: "gray", d: "M50.767 -62.491H53.457V-57.295H50.767z" }),
            React.createElement("path", { fill: "gray", d: "M50.767 -57.295H53.457V-52.099000000000004H50.767z" }),
            React.createElement("path", { fill: "gray", d: "M50.767 -52.098H53.457V-46.902H50.767z" }),
            React.createElement("path", { fill: "gray", d: "M50.767 -46.902H53.457V-41.706H50.767z" }),
            React.createElement("path", { fill: "gray", d: "M50.767 -41.706H53.457V-36.510000000000005H50.767z" }),
            React.createElement("path", { fill: "gray", d: "M50.767 -36.51H53.457V-31.314H50.767z" }),
            React.createElement("path", { fill: "gray", d: "M30.602 -78.422H33.292V-73.226H30.602z" }),
            React.createElement("path", { fill: "gray", d: "M30.602 -73.226H33.292V-68.03H30.602z" }),
            React.createElement("path", { fill: "gray", d: "M30.602 -68.03H33.292V-62.834H30.602z" }),
            React.createElement("path", { fill: "gray", d: "M30.602 -62.834H33.292V-57.638000000000005H30.602z" }),
            React.createElement("path", { fill: "gray", d: "M30.602 -57.637H33.292V-52.441H30.602z" }),
            React.createElement("path", { fill: "gray", d: "M30.602 -52.441H33.292V-47.245000000000005H30.602z" }),
            React.createElement("path", { fill: "gray", d: "M30.602 -47.245H33.292V-42.049H30.602z" }),
            React.createElement("path", { fill: "gray", d: "M30.602 -42.049H33.292V-36.853H30.602z" }),
            React.createElement("path", { fill: "gray", d: "M30.602 -36.852H33.292V-31.656H30.602z" }),
            React.createElement("path", { fill: "#ccc", d: "M33.285 -74.072H38.3V-71.63H33.285z" }),
            React.createElement("path", { fill: "#ccc", d: "M38.3 -74.072H43.315V-71.63H38.3z" }),
            React.createElement("path", { fill: "#ccc", d: "M43.315 -74.072H48.33V-71.63H43.315z" }),
            React.createElement("path", { fill: "#ccc", d: "M45.752 -71.631H50.767V-69.189H45.752z" }),
            React.createElement("path", { fill: "#ccc", d: "M40.737 -71.631H45.752V-69.189H40.737z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.722 -71.631H40.737V-69.189H35.722z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.33 -74.072H50.766999999999996V-71.63H48.33z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.33 -74.072H50.766999999999996V-71.63H48.33z" }),
            React.createElement("path", { fill: "#ccc", d: "M33.285 -71.631H35.721999999999994V-69.189H33.285z" }),
            React.createElement("path", { fill: "#ccc", d: "M33.285 -69.189H38.3V-66.74699999999999H33.285z" }),
            React.createElement("path", { fill: "#ccc", d: "M38.3 -69.189H43.315V-66.74699999999999H38.3z" }),
            React.createElement("path", { fill: "#ccc", d: "M43.315 -69.189H48.33V-66.74699999999999H43.315z" }),
            React.createElement("path", { fill: "#ccc", d: "M45.752 -66.748H50.767V-64.30600000000001H45.752z" }),
            React.createElement("path", { fill: "#ccc", d: "M40.737 -66.748H45.752V-64.30600000000001H40.737z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.722 -66.748H40.737V-64.30600000000001H35.722z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.33 -69.189H50.766999999999996V-66.74699999999999H48.33z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.33 -69.189H50.766999999999996V-66.74699999999999H48.33z" }),
            React.createElement("path", { fill: "#ccc", d: "M33.285 -66.748H35.721999999999994V-64.30600000000001H33.285z" }),
            React.createElement("path", { fill: "#ccc", d: "M33.285 -64.306H38.3V-61.864H33.285z" }),
            React.createElement("path", { fill: "#ccc", d: "M38.3 -64.306H43.315V-61.864H38.3z" }),
            React.createElement("path", { fill: "#ccc", d: "M43.315 -64.306H48.33V-61.864H43.315z" }),
            React.createElement("path", { fill: "#ccc", d: "M45.752 -61.865H50.767V-59.423H45.752z" }),
            React.createElement("path", { fill: "#ccc", d: "M40.737 -61.865H45.752V-59.423H40.737z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.722 -61.865H40.737V-59.423H35.722z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.33 -64.306H50.766999999999996V-61.864H48.33z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.33 -64.306H50.766999999999996V-61.864H48.33z" }),
            React.createElement("path", { fill: "#ccc", d: "M33.285 -61.865H35.721999999999994V-59.423H33.285z" }),
            React.createElement("path", { fill: "#ccc", d: "M33.285 -59.423H38.3V-56.981H33.285z" }),
            React.createElement("path", { fill: "#ccc", d: "M38.3 -59.423H43.315V-56.981H38.3z" }),
            React.createElement("path", { fill: "#ccc", d: "M43.315 -59.423H48.33V-56.981H43.315z" }),
            React.createElement("path", { fill: "#ccc", d: "M45.752 -56.981H50.767V-54.539H45.752z" }),
            React.createElement("path", { fill: "#ccc", d: "M40.737 -56.981H45.752V-54.539H40.737z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.722 -56.981H40.737V-54.539H35.722z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.33 -59.423H50.766999999999996V-56.981H48.33z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.33 -59.423H50.766999999999996V-56.981H48.33z" }),
            React.createElement("path", { fill: "#ccc", d: "M33.285 -56.981H35.721999999999994V-54.539H33.285z" }),
            React.createElement("path", { fill: "#ccc", d: "M33.285 -54.54H38.3V-52.098H33.285z" }),
            React.createElement("path", { fill: "#ccc", d: "M38.3 -54.54H43.315V-52.098H38.3z" }),
            React.createElement("path", { fill: "#ccc", d: "M43.315 -54.54H48.33V-52.098H43.315z" }),
            React.createElement("path", { fill: "#ccc", d: "M45.752 -52.098H50.767V-49.656H45.752z" }),
            React.createElement("path", { fill: "#ccc", d: "M40.737 -52.098H45.752V-49.656H40.737z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.722 -52.098H40.737V-49.656H35.722z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.33 -54.54H50.766999999999996V-52.098H48.33z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.33 -54.54H50.766999999999996V-52.098H48.33z" }),
            React.createElement("path", { fill: "#ccc", d: "M33.285 -52.098H35.721999999999994V-49.656H33.285z" }),
            React.createElement("path", { fill: "#ccc", d: "M33.285 -49.657H38.3V-47.214999999999996H33.285z" }),
            React.createElement("path", { fill: "#ccc", d: "M38.3 -49.657H43.315V-47.214999999999996H38.3z" }),
            React.createElement("path", { fill: "#ccc", d: "M43.315 -49.657H48.33V-47.214999999999996H43.315z" }),
            React.createElement("path", { fill: "#ccc", d: "M45.752 -47.215H50.767V-44.773H45.752z" }),
            React.createElement("path", { fill: "#ccc", d: "M40.737 -47.215H45.752V-44.773H40.737z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.722 -47.215H40.737V-44.773H35.722z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.33 -49.657H50.766999999999996V-47.214999999999996H48.33z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.33 -49.657H50.766999999999996V-47.214999999999996H48.33z" }),
            React.createElement("path", { fill: "#ccc", d: "M33.285 -47.215H35.721999999999994V-44.773H33.285z" }),
            React.createElement("path", { fill: "#ccc", d: "M33.285 -44.774H38.3V-42.332H33.285z" }),
            React.createElement("path", { fill: "#ccc", d: "M38.3 -44.774H43.315V-42.332H38.3z" }),
            React.createElement("path", { fill: "#ccc", d: "M43.315 -44.774H48.33V-42.332H43.315z" }),
            React.createElement("path", { fill: "#ccc", d: "M45.752 -42.332H50.767V-39.89H45.752z" }),
            React.createElement("path", { fill: "#ccc", d: "M40.737 -42.332H45.752V-39.89H40.737z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.722 -42.332H40.737V-39.89H35.722z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.33 -44.774H50.766999999999996V-42.332H48.33z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.33 -44.774H50.766999999999996V-42.332H48.33z" }),
            React.createElement("path", { fill: "#ccc", d: "M33.285 -42.332H35.721999999999994V-39.89H33.285z" }),
            React.createElement("path", { fill: "#ccc", d: "M33.285 -39.89H38.3V-37.448H33.285z" }),
            React.createElement("path", { fill: "#ccc", d: "M38.3 -39.89H43.315V-37.448H38.3z" }),
            React.createElement("path", { fill: "#ccc", d: "M43.315 -39.89H48.33V-37.448H43.315z" }),
            React.createElement("path", { fill: "#ccc", d: "M45.752 -37.449H50.767V-35.007H45.752z" }),
            React.createElement("path", { fill: "#ccc", d: "M40.737 -37.449H45.752V-35.007H40.737z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.722 -37.449H40.737V-35.007H35.722z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.33 -39.89H50.766999999999996V-37.448H48.33z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.33 -39.89H50.766999999999996V-37.448H48.33z" }),
            React.createElement("path", { fill: "#ccc", d: "M33.285 -37.449H35.721999999999994V-35.007H33.285z" }),
            React.createElement("path", { fill: "gray", d: "M50.767 -31.313H53.457V-26.116999999999997H50.767z" }),
            React.createElement("path", { fill: "gray", d: "M50.767 -26.117H53.457V-20.921H50.767z" }),
            React.createElement("path", { fill: "gray", d: "M50.767 -20.921H53.457V-15.725H50.767z" }),
            React.createElement("path", { fill: "gray", d: "M50.767 -83.276H53.457V-78.08H50.767z" }),
            React.createElement("path", { fill: "gray", d: "M30.602 -31.656H33.292V-26.46H30.602z" }),
            React.createElement("path", { fill: "gray", d: "M30.602 -26.46H33.292V-21.264000000000003H30.602z" }),
            React.createElement("path", { fill: "gray", d: "M30.602 -21.264H33.292V-16.067999999999998H30.602z" }),
            React.createElement("path", { fill: "gray", d: "M30.602 -83.619H33.292V-78.423H30.602z" }),
            React.createElement("path", { fill: "#ccc", d: "M33.285 -35.007H38.3V-32.565H33.285z" }),
            React.createElement("path", { fill: "#ccc", d: "M38.3 -35.007H43.315V-32.565H38.3z" }),
            React.createElement("path", { fill: "#ccc", d: "M43.315 -35.007H48.33V-32.565H43.315z" }),
            React.createElement("path", { fill: "#ccc", d: "M45.752 -32.566H50.767V-30.124000000000002H45.752z" }),
            React.createElement("path", { fill: "#ccc", d: "M40.737 -32.566H45.752V-30.124000000000002H40.737z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.722 -32.566H40.737V-30.124000000000002H35.722z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.33 -35.007H50.766999999999996V-32.565H48.33z" }),
            React.createElement("path", { fill: "#ccc", d: "M33.285 -32.566H35.721999999999994V-30.124000000000002H33.285z" }),
            React.createElement("path", { fill: "#ccc", d: "M33.285 -30.124H38.3V-27.682H33.285z" }),
            React.createElement("path", { fill: "#ccc", d: "M38.3 -30.124H43.315V-27.682H38.3z" }),
            React.createElement("path", { fill: "#ccc", d: "M43.315 -30.124H48.33V-27.682H43.315z" }),
            React.createElement("path", { fill: "#ccc", d: "M45.752 -27.683H50.767V-25.241H45.752z" }),
            React.createElement("path", { fill: "#ccc", d: "M40.737 -27.683H45.752V-25.241H40.737z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.722 -27.683H40.737V-25.241H35.722z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.33 -30.124H50.766999999999996V-27.682H48.33z" }),
            React.createElement("path", { fill: "#ccc", d: "M33.285 -27.683H35.721999999999994V-25.241H33.285z" }),
            React.createElement("path", { fill: "#ccc", d: "M33.285 -25.241H38.3V-22.799H33.285z" }),
            React.createElement("path", { fill: "#ccc", d: "M38.3 -25.241H43.315V-22.799H38.3z" }),
            React.createElement("path", { fill: "#ccc", d: "M43.315 -25.241H48.33V-22.799H43.315z" }),
            React.createElement("path", { fill: "#ccc", d: "M45.752 -22.8H50.767V-20.358H45.752z" }),
            React.createElement("path", { fill: "#ccc", d: "M40.737 -22.8H45.752V-20.358H40.737z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.722 -22.8H40.737V-20.358H35.722z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.33 -25.241H50.766999999999996V-22.799H48.33z" }),
            React.createElement("path", { fill: "#ccc", d: "M33.285 -22.8H35.721999999999994V-20.358H33.285z" }),
            React.createElement("path", { fill: "#ccc", d: "M33.293 -78.422H38.308V-75.97999999999999H33.293z" }),
            React.createElement("path", { fill: "#ccc", d: "M38.308 -78.422H43.323V-75.97999999999999H38.308z" }),
            React.createElement("path", { fill: "#ccc", d: "M43.323 -78.422H48.338V-75.97999999999999H43.323z" }),
            React.createElement("path", { fill: "#ccc", d: "M45.76 -75.981H50.775V-73.53899999999999H45.76z" }),
            React.createElement("path", { fill: "#ccc", d: "M40.745 -75.981H45.76V-73.53899999999999H40.745z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.73 -75.981H40.745V-73.53899999999999H35.73z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.338 -78.422H50.775V-75.97999999999999H48.338z" }),
            React.createElement("path", { fill: "#ccc", d: "M33.293 -75.981H35.73V-73.53899999999999H33.293z" })))) : (React.createElement("g", null,
        React.createElement("path", { fill: "#1e90ff", fillOpacity: "1", stroke: "#000", strokeWidth: "1", d: "M0 62.5v-25c35.493-6.193 47.236-13.759 86.603 0v25c-42.79-3.505-25.585-5.816-86.603 0z" }),
        React.createElement("g", { fillOpacity: "1", stroke: "#000", strokeDasharray: "none", strokeLinecap: "round", strokeLinejoin: "miter", strokeMiterlimit: "4", strokeWidth: "0.129" },
            React.createElement("path", { fill: "gray", d: "M53.046 21.496H55.736V26.692H53.046z" }),
            React.createElement("path", { fill: "gray", d: "M53.046 26.692H55.736V31.887999999999998H53.046z" }),
            React.createElement("path", { fill: "gray", d: "M53.046 31.888H55.736V37.084H53.046z" }),
            React.createElement("path", { fill: "gray", d: "M53.046 37.085H55.736V42.281H53.046z" }),
            React.createElement("path", { fill: "gray", d: "M53.046 42.281H55.736V47.477H53.046z" }),
            React.createElement("path", { fill: "gray", d: "M53.046 47.477H55.736V52.672999999999995H53.046z" }),
            React.createElement("path", { fill: "gray", d: "M53.046 52.673H55.736V57.869H53.046z" }),
            React.createElement("path", { fill: "gray", d: "M53.046 57.87H55.736V63.065999999999995H53.046z" }),
            React.createElement("path", { fill: "gray", d: "M53.046 63.066H55.736V68.262H53.046z" }),
            React.createElement("path", { fill: "gray", d: "M32.881 21.153H35.571V26.348999999999997H32.881z" }),
            React.createElement("path", { fill: "gray", d: "M32.881 26.349H35.571V31.545H32.881z" }),
            React.createElement("path", { fill: "gray", d: "M32.881 31.546H35.571V36.742H32.881z" }),
            React.createElement("path", { fill: "gray", d: "M32.881 36.742H35.571V41.937999999999995H32.881z" }),
            React.createElement("path", { fill: "gray", d: "M32.881 41.938H35.571V47.134H32.881z" }),
            React.createElement("path", { fill: "gray", d: "M32.881 47.134H35.571V52.33H32.881z" }),
            React.createElement("path", { fill: "gray", d: "M32.881 52.331H35.571V57.527H32.881z" }),
            React.createElement("path", { fill: "gray", d: "M32.881 57.527H35.571V62.723H32.881z" }),
            React.createElement("path", { fill: "gray", d: "M32.881 62.723H35.571V67.919H32.881z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.564 25.503H40.579V27.945H35.564z" }),
            React.createElement("path", { fill: "#ccc", d: "M40.579 25.503H45.594V27.945H40.579z" }),
            React.createElement("path", { fill: "#ccc", d: "M45.594 25.503H50.609V27.945H45.594z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.031 27.945H53.046V30.387H48.031z" }),
            React.createElement("path", { fill: "#ccc", d: "M43.016 27.945H48.031V30.387H43.016z" }),
            React.createElement("path", { fill: "#ccc", d: "M38.001 27.945H43.016V30.387H38.001z" }),
            React.createElement("path", { fill: "#ccc", d: "M50.609 25.503H53.046V27.945H50.609z" }),
            React.createElement("path", { fill: "#ccc", d: "M50.609 25.503H53.046V27.945H50.609z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.564 27.945H38.001V30.387H35.564z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.564 30.386H40.579V32.828H35.564z" }),
            React.createElement("path", { fill: "#ccc", d: "M40.579 30.386H45.594V32.828H40.579z" }),
            React.createElement("path", { fill: "#ccc", d: "M45.594 30.386H50.609V32.828H45.594z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.031 32.828H53.046V35.27H48.031z" }),
            React.createElement("path", { fill: "#ccc", d: "M43.016 32.828H48.031V35.27H43.016z" }),
            React.createElement("path", { fill: "#ccc", d: "M38.001 32.828H43.016V35.27H38.001z" }),
            React.createElement("path", { fill: "#ccc", d: "M50.609 30.386H53.046V32.828H50.609z" }),
            React.createElement("path", { fill: "#ccc", d: "M50.609 30.386H53.046V32.828H50.609z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.564 32.828H38.001V35.27H35.564z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.564 35.269H40.579V37.711H35.564z" }),
            React.createElement("path", { fill: "#ccc", d: "M40.579 35.269H45.594V37.711H40.579z" }),
            React.createElement("path", { fill: "#ccc", d: "M45.594 35.269H50.609V37.711H45.594z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.031 37.711H53.046V40.153H48.031z" }),
            React.createElement("path", { fill: "#ccc", d: "M43.016 37.711H48.031V40.153H43.016z" }),
            React.createElement("path", { fill: "#ccc", d: "M38.001 37.711H43.016V40.153H38.001z" }),
            React.createElement("path", { fill: "#ccc", d: "M50.609 35.269H53.046V37.711H50.609z" }),
            React.createElement("path", { fill: "#ccc", d: "M50.609 35.269H53.046V37.711H50.609z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.564 37.711H38.001V40.153H35.564z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.564 40.152H40.579V42.594H35.564z" }),
            React.createElement("path", { fill: "#ccc", d: "M40.579 40.152H45.594V42.594H40.579z" }),
            React.createElement("path", { fill: "#ccc", d: "M45.594 40.152H50.609V42.594H45.594z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.031 42.594H53.046V45.036H48.031z" }),
            React.createElement("path", { fill: "#ccc", d: "M43.016 42.594H48.031V45.036H43.016z" }),
            React.createElement("path", { fill: "#ccc", d: "M38.001 42.594H43.016V45.036H38.001z" }),
            React.createElement("path", { fill: "#ccc", d: "M50.609 40.152H53.046V42.594H50.609z" }),
            React.createElement("path", { fill: "#ccc", d: "M50.609 40.152H53.046V42.594H50.609z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.564 42.594H38.001V45.036H35.564z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.564 45.036H40.579V47.478H35.564z" }),
            React.createElement("path", { fill: "#ccc", d: "M40.579 45.036H45.594V47.478H40.579z" }),
            React.createElement("path", { fill: "#ccc", d: "M45.594 45.036H50.609V47.478H45.594z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.031 47.477H53.046V49.919H48.031z" }),
            React.createElement("path", { fill: "#ccc", d: "M43.016 47.477H48.031V49.919H43.016z" }),
            React.createElement("path", { fill: "#ccc", d: "M38.001 47.477H43.016V49.919H38.001z" }),
            React.createElement("path", { fill: "#ccc", d: "M50.609 45.036H53.046V47.478H50.609z" }),
            React.createElement("path", { fill: "#ccc", d: "M50.609 45.036H53.046V47.478H50.609z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.564 47.477H38.001V49.919H35.564z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.564 49.919H40.579V52.361H35.564z" }),
            React.createElement("path", { fill: "#ccc", d: "M40.579 49.919H45.594V52.361H40.579z" }),
            React.createElement("path", { fill: "#ccc", d: "M45.594 49.919H50.609V52.361H45.594z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.031 52.36H53.046V54.802H48.031z" }),
            React.createElement("path", { fill: "#ccc", d: "M43.016 52.36H48.031V54.802H43.016z" }),
            React.createElement("path", { fill: "#ccc", d: "M38.001 52.36H43.016V54.802H38.001z" }),
            React.createElement("path", { fill: "#ccc", d: "M50.609 49.919H53.046V52.361H50.609z" }),
            React.createElement("path", { fill: "#ccc", d: "M50.609 49.919H53.046V52.361H50.609z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.564 52.36H38.001V54.802H35.564z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.564 54.802H40.579V57.244H35.564z" }),
            React.createElement("path", { fill: "#ccc", d: "M40.579 54.802H45.594V57.244H40.579z" }),
            React.createElement("path", { fill: "#ccc", d: "M45.594 54.802H50.609V57.244H45.594z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.031 57.243H53.046V59.685H48.031z" }),
            React.createElement("path", { fill: "#ccc", d: "M43.016 57.243H48.031V59.685H43.016z" }),
            React.createElement("path", { fill: "#ccc", d: "M38.001 57.243H43.016V59.685H38.001z" }),
            React.createElement("path", { fill: "#ccc", d: "M50.609 54.802H53.046V57.244H50.609z" }),
            React.createElement("path", { fill: "#ccc", d: "M50.609 54.802H53.046V57.244H50.609z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.564 57.243H38.001V59.685H35.564z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.564 59.685H40.579V62.127H35.564z" }),
            React.createElement("path", { fill: "#ccc", d: "M40.579 59.685H45.594V62.127H40.579z" }),
            React.createElement("path", { fill: "#ccc", d: "M45.594 59.685H50.609V62.127H45.594z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.031 62.127H53.046V64.569H48.031z" }),
            React.createElement("path", { fill: "#ccc", d: "M43.016 62.127H48.031V64.569H43.016z" }),
            React.createElement("path", { fill: "#ccc", d: "M38.001 62.127H43.016V64.569H38.001z" }),
            React.createElement("path", { fill: "#ccc", d: "M50.609 59.685H53.046V62.127H50.609z" }),
            React.createElement("path", { fill: "#ccc", d: "M50.609 59.685H53.046V62.127H50.609z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.564 62.127H38.001V64.569H35.564z" }),
            React.createElement("path", { fill: "gray", d: "M53.046 68.262H55.736V73.458H53.046z" }),
            React.createElement("path", { fill: "gray", d: "M53.046 73.458H55.736V78.654H53.046z" }),
            React.createElement("path", { fill: "gray", d: "M53.046 78.655H55.736V83.851H53.046z" }),
            React.createElement("path", { fill: "gray", d: "M53.046 16.3H55.736V21.496000000000002H53.046z" }),
            React.createElement("path", { fill: "gray", d: "M32.881 67.919H35.571V73.115H32.881z" }),
            React.createElement("path", { fill: "gray", d: "M32.881 73.116H35.571V78.312H32.881z" }),
            React.createElement("path", { fill: "gray", d: "M32.881 78.312H35.571V83.508H32.881z" }),
            React.createElement("path", { fill: "gray", d: "M32.881 15.957H35.571V21.153H32.881z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.564 64.568H40.579V67.00999999999999H35.564z" }),
            React.createElement("path", { fill: "#ccc", d: "M40.579 64.568H45.594V67.00999999999999H40.579z" }),
            React.createElement("path", { fill: "#ccc", d: "M45.594 64.568H50.609V67.00999999999999H45.594z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.031 67.01H53.046V69.452H48.031z" }),
            React.createElement("path", { fill: "#ccc", d: "M43.016 67.01H48.031V69.452H43.016z" }),
            React.createElement("path", { fill: "#ccc", d: "M38.001 67.01H43.016V69.452H38.001z" }),
            React.createElement("path", { fill: "#ccc", d: "M50.609 64.568H53.046V67.00999999999999H50.609z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.564 67.01H38.001V69.452H35.564z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.564 69.451H40.579V71.893H35.564z" }),
            React.createElement("path", { fill: "#ccc", d: "M40.579 69.451H45.594V71.893H40.579z" }),
            React.createElement("path", { fill: "#ccc", d: "M45.594 69.451H50.609V71.893H45.594z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.031 71.893H53.046V74.33500000000001H48.031z" }),
            React.createElement("path", { fill: "#ccc", d: "M43.016 71.893H48.031V74.33500000000001H43.016z" }),
            React.createElement("path", { fill: "#ccc", d: "M38.001 71.893H43.016V74.33500000000001H38.001z" }),
            React.createElement("path", { fill: "#ccc", d: "M50.609 69.451H53.046V71.893H50.609z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.564 71.893H38.001V74.33500000000001H35.564z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.564 74.334H40.579V76.77600000000001H35.564z" }),
            React.createElement("path", { fill: "#ccc", d: "M40.579 74.334H45.594V76.77600000000001H40.579z" }),
            React.createElement("path", { fill: "#ccc", d: "M45.594 74.334H50.609V76.77600000000001H45.594z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.031 76.776H53.046V79.21799999999999H48.031z" }),
            React.createElement("path", { fill: "#ccc", d: "M43.016 76.776H48.031V79.21799999999999H43.016z" }),
            React.createElement("path", { fill: "#ccc", d: "M38.001 76.776H43.016V79.21799999999999H38.001z" }),
            React.createElement("path", { fill: "#ccc", d: "M50.609 74.334H53.046V76.77600000000001H50.609z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.564 76.776H38.001V79.21799999999999H35.564z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.572 21.153H40.587V23.595H35.572z" }),
            React.createElement("path", { fill: "#ccc", d: "M40.587 21.153H45.602000000000004V23.595H40.587z" }),
            React.createElement("path", { fill: "#ccc", d: "M45.602 21.153H50.617V23.595H45.602z" }),
            React.createElement("path", { fill: "#ccc", d: "M48.039 23.595H53.054V26.037H48.039z" }),
            React.createElement("path", { fill: "#ccc", d: "M43.024 23.595H48.039V26.037H43.024z" }),
            React.createElement("path", { fill: "#ccc", d: "M38.009 23.595H43.024V26.037H38.009z" }),
            React.createElement("path", { fill: "#ccc", d: "M50.617 21.153H53.053999999999995V23.595H50.617z" }),
            React.createElement("path", { fill: "#ccc", d: "M35.572 23.595H38.009V26.037H35.572z" }))))));
}
