import { BattleBonusPurpose, createAttackGameDieBonus, createDefenseGameDieBonus, createSymbolBonus, } from '../codebook/battleBonus.js';
import { AttackDieType, CombatEffectSymbol, DefenseDieType } from '../codebook/dice.js';
export var Terrain;
(function (Terrain) {
    Terrain["Plain"] = "plain";
    Terrain["Wood"] = "wood";
    Terrain["River"] = "river";
    Terrain["Hill"] = "hill";
    Terrain["Bridge"] = "bridge";
})(Terrain || (Terrain = {}));
export var TerrainId;
(function (TerrainId) {
    TerrainId[TerrainId["Plain"] = 1] = "Plain";
    TerrainId[TerrainId["Wood"] = 2] = "Wood";
    TerrainId[TerrainId["River"] = 3] = "River";
    TerrainId[TerrainId["Hill"] = 4] = "Hill";
    TerrainId[TerrainId["Bridge"] = 5] = "Bridge";
})(TerrainId || (TerrainId = {}));
const possibleTerrains = [
    {
        id: TerrainId.Plain,
        name: 'Clear',
        isBlocking: false,
        isPassable: true,
        movementCost: 1,
        bonuses: [],
    },
    {
        id: TerrainId.Wood,
        name: 'Woods',
        isBlocking: true,
        isPassable: true,
        movementCost: 2,
        bonuses: [createDefenseGameDieBonus(DefenseDieType.Brown)],
    },
    {
        id: TerrainId.River,
        name: 'River',
        isBlocking: false,
        isPassable: false,
        bonuses: [],
    },
    {
        id: TerrainId.Hill,
        name: 'Hill',
        isBlocking: true,
        isPassable: true,
        movementCost: 2,
        bonuses: [
            createAttackGameDieBonus(AttackDieType.Yellow),
            createSymbolBonus(BattleBonusPurpose.Defense, CombatEffectSymbol.Shield),
        ],
    },
    {
        id: TerrainId.Bridge,
        name: 'Bridge',
        isBlocking: false,
        isPassable: true,
        movementCost: 1,
        bonuses: [createDefenseGameDieBonus(DefenseDieType.Brown)],
    },
];
export function isBlockingTerrain(terrainId) {
    const terrain = possibleTerrains.find((t) => t.id === terrainId);
    return terrain?.isBlocking ?? false;
}
export function isPassableTerrain(terrainId) {
    const terrain = possibleTerrains.find((t) => t.id === terrainId);
    return terrain?.isPassable ?? true;
}
export function getTerrainBonuses(terrainId) {
    const terrain = possibleTerrains.find((t) => t.id === terrainId);
    return terrain?.bonuses ?? [];
}
export function getTerrainName(terrainId) {
    const terrain = possibleTerrains.find((t) => t.id === terrainId);
    return terrain?.name ?? '';
}
export function getMovementCost(terrainId) {
    const terrain = possibleTerrains.find((t) => t.id === terrainId);
    if (terrain?.isPassable) {
        return terrain.movementCost;
    }
    return null;
}
export function getPassableTerrains() {
    return possibleTerrains.filter((t) => isPassableTerrain(t.id));
}
