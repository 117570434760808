import React from 'react';
import Hex from '../../map/Hex.js';
import { hexCorner } from '@wareena/game-algorithms';
import Single1 from './variant/Single1.js';
import { createVariants } from '../utils/withRotation.js';
import OneAdjacent1 from './variant/OneAdjacent1.js';
import TwoAdjacents1 from './variant/TwoAdjacents1.js';
import ThreeAdjacents1 from './variant/ThreeAdjacents1.js';
import FourAdjacents1 from './variant/FourAdjacents1.js';
import FiveAdjacents1 from './variant/FiveAdjacents.js';
import Ridge1 from './variant/Ridge1.js';
import Turn1 from './variant/Turn1.js';
import { TERRAIN_COLOR_PLAIN } from '../colors.js';
const variants = {
    default: Single1,
    ...createVariants('single1', Single1),
    ...createVariants('oneAdjacent1', OneAdjacent1),
    ...createVariants('twoAdjacents1', TwoAdjacents1),
    ...createVariants('threeAdjacents1', ThreeAdjacents1),
    ...createVariants('fourAdjacents1', FourAdjacents1),
    ...createVariants('fiveAdjacents1', FiveAdjacents1),
    ...createVariants('ridge1', Ridge1),
    ...createVariants('turn1', Turn1),
};
function HillHex({ style = {}, children, ...props }) {
    let variantName = props.graphicalVariant ?? 'default';
    if (variants[variantName] === undefined) {
        variantName = 'default';
    }
    const Variant = variants[variantName];
    if (variantName === 'default') {
        return (React.createElement(Hex, { ...props, style: {
                ...style,
                fill: '#996633',
            } }, children));
    }
    return (React.createElement(Hex, { ...props, style: {
            ...style,
            fill: TERRAIN_COLOR_PLAIN,
        } },
        React.createElement(Variant, { ...props }),
        children));
}
function Variant1({ center, size, isFlatTop, }) {
    const hexCorners = [
        hexCorner(center, size, 0, isFlatTop),
        hexCorner(center, size, 1, isFlatTop),
        hexCorner(center, size, 2, isFlatTop),
        hexCorner(center, size, 3, isFlatTop),
        hexCorner(center, size, 4, isFlatTop),
        hexCorner(center, size, 5, isFlatTop),
    ];
    const corners = [
        hexCorners[0],
        {
            x: hexCorners[0].x + (hexCorners[1].x - hexCorners[0].x) / 4,
            y: hexCorners[0].y + (hexCorners[1].y - hexCorners[0].y) / 4,
        },
        {
            x: hexCorners[0].x + ((hexCorners[1].x - hexCorners[0].x) * 3) / 4,
            y: hexCorners[0].y + ((hexCorners[1].y - hexCorners[0].y) * 3) / 4,
        },
        hexCorners[1],
        {
            x: hexCorners[1].x - (hexCorners[1].x - hexCorners[2].x) / 4,
            y: hexCorners[1].y - (hexCorners[1].y - hexCorners[2].y) / 4,
        },
        {
            x: hexCorners[1].x - ((hexCorners[1].x - hexCorners[2].x) * 3) / 4,
            y: hexCorners[1].y - ((hexCorners[1].y - hexCorners[2].y) * 3) / 4,
        },
        hexCorners[2],
        {
            x: hexCorners[2].x,
            y: hexCorners[2].y - (hexCorners[2].y - hexCorners[3].y) / 4,
        },
        {
            x: hexCorners[2].x,
            y: hexCorners[2].y - ((hexCorners[2].y - hexCorners[3].y) * 3) / 4,
        },
        hexCorners[3],
        {
            x: hexCorners[3].x + (hexCorners[4].x - hexCorners[3].x) / 4,
            y: hexCorners[3].y - (hexCorners[3].y - hexCorners[4].y) / 4,
        },
        {
            x: hexCorners[3].x + ((hexCorners[4].x - hexCorners[3].x) * 3) / 4,
            y: hexCorners[3].y - ((hexCorners[3].y - hexCorners[4].y) * 3) / 4,
        },
        hexCorners[4],
        {
            x: hexCorners[4].x + (hexCorners[5].x - hexCorners[4].x) / 4,
            y: hexCorners[4].y + (hexCorners[5].y - hexCorners[4].y) / 4,
        },
        {
            x: hexCorners[4].x + ((hexCorners[5].x - hexCorners[4].x) * 3) / 4,
            y: hexCorners[4].y + ((hexCorners[5].y - hexCorners[4].y) * 3) / 4,
        },
        hexCorners[5],
        {
            x: hexCorners[5].x,
            y: hexCorners[5].y + (hexCorners[0].y - hexCorners[5].y) / 4,
        },
        {
            x: hexCorners[5].x,
            y: hexCorners[5].y + ((hexCorners[0].y - hexCorners[5].y) * 3) / 4,
        },
    ];
    return (React.createElement("path", { fill: "#996633", stroke: "black", strokeWidth: 1, d: `M ${corners[0].x},${corners[0].y} ${corners
            .slice(1)
            .map((corner) => `L${corner.x},${corner.y}`)
            .join(' ')}
             Z` }));
}
export const graphicalVariants = Object.keys(variants);
export default HillHex;
