import React from 'react';
import { getTerrainBonuses } from '@wareena/grid-battles-entity/game/map/terrain';
import DieIcon from '../unit/dice/DieIcon.js';
import { BattleBonusType } from '@wareena/grid-battles-entity/game/codebook/battleBonus';
import SymbolIcon from '../unit/dice/SymbolIcon.js';
export default function TerrainWithBonuses({ terrainId, terrainHex, ...hexProps }) {
    const TerrainHex = terrainHex;
    const { center, isFlatTop, dimensions } = hexProps;
    const counterSize = isFlatTop ? dimensions.height / 1.5 : dimensions.width / 1.5;
    const symbolSize = counterSize / 8;
    const bonuses = getTerrainBonuses(terrainId);
    const distanceFromCounter = symbolSize / 2;
    const start = isFlatTop
        ? {
            x: center.x - counterSize / 2 + symbolSize / 2,
            y: center.y + counterSize / 2 + distanceFromCounter,
        }
        : {
            x: center.x + counterSize / 2 + distanceFromCounter,
            y: center.y - counterSize / 2 + symbolSize / 2,
        };
    return (React.createElement(TerrainHex, { ...hexProps }, bonuses.map((bonus, idx) => {
        const position = isFlatTop
            ? {
                x: start.x + idx * symbolSize * 1.5,
                y: start.y,
            }
            : {
                x: start.x,
                y: start.y + idx * symbolSize * 1.5,
            };
        if (bonus.type === BattleBonusType.GameDie) {
            return (React.createElement("g", { key: `bonus-${idx}`, style: {
                    filter: 'drop-shadow(1px 1px 1px rgba(0, 0, 0, .7))',
                } },
                React.createElement(DieIcon, { type: bonus.gameDie, origin: position, size: symbolSize })));
        }
        if (bonus.type === BattleBonusType.CombatEffectSymbol) {
            return (React.createElement("g", { key: `bonus-${idx}`, style: {
                    filter: 'drop-shadow(1px 1px 1px rgba(0, 0, 0, .7))',
                } },
                React.createElement(SymbolIcon, { symbol: bonus.symbol, origin: position, size: symbolSize })));
        }
        return null;
    })));
}
