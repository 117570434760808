import React from 'react';
import Arrow from '@wareena/ui/game/markers/Arrow';
const movementSymbolColors = {
    withMovementPoints: {
        stroke: '#a1b280',
        fill: '#a1d280',
    },
    withoutMovementPoints: {
        stroke: '#aaa',
        fill: '#ccc',
    },
};
export default function MovementSymbol({ movementPoints, position, counterSize, }) {
    const movementColors = movementPoints
        ? movementSymbolColors.withMovementPoints
        : movementSymbolColors.withoutMovementPoints;
    const pixelSize = counterSize / 60;
    const quarterSize = counterSize / 4;
    const idFontSize = Math.round((quarterSize * 2) / 3);
    return (React.createElement(React.Fragment, null,
        React.createElement(Arrow, { angle: 225, tipPoint: position, baseSize: 14 * pixelSize, style: { stroke: movementColors.stroke, fill: movementColors.fill } }),
        React.createElement("text", { x: position.x + counterSize / 13, y: position.y + idFontSize - 2 * pixelSize, fontFamily: "Verdana", fontWeight: "bold", fontSize: idFontSize, textAnchor: "middle" }, movementPoints)));
}
